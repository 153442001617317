import * as React from 'react';
import { IRioModelErrors, RioIfFunc, RioInput, RioResources, RioSelect, RioValidator } from "rio.react.all";
import { DeliveryEstimateApi } from "../apiLayer/DeliveryEstimateApi";
import { DeliveryEstimateApiModel } from "../apiModels/DeliveryEstimate/deliveryEstimateApiModel";
import { DeliveryEstimateResultApiModel } from "../apiModels/DeliveryEstimate/deliveryEstimateResultApiModel";
import { SelectOption } from '../helpers/RioSelectOption';


interface DeliveryEstimateProps {
}

interface DeliveryEstimateState {
    deliveryEstimate: DeliveryEstimateApiModel;
    deliveryEstimateResult: DeliveryEstimateResultApiModel;
    loading: boolean;
    errors: IRioModelErrors;
    error: boolean;
    deliveryProviders: SelectOption[];
    selectedDeliveryProvider: SelectOption;
}

export class DeliveryEstimate extends React.Component<DeliveryEstimateProps, DeliveryEstimateState> {

    constructor(props: DeliveryEstimateProps) {
        super(props);
        this.state = {
            deliveryEstimate: new DeliveryEstimateApiModel(),
            deliveryEstimateResult: new DeliveryEstimateResultApiModel(),
            loading: false,
            error: false,
            deliveryProviders: [],
            errors: null,
            selectedDeliveryProvider: null
        };
    }

    private validator = new RioValidator();

    componentDidMount() {
        DeliveryEstimateApi.getDeliveryProviders(response => {
            let deliveryEstimate = {
                ...this.state.deliveryEstimate,
                organisationID: parseInt(response.item[0].value)
            };

            this.setState({
                deliveryProviders: response.item,
                deliveryEstimate: deliveryEstimate
            });
        });
    }

    render() {
        return (
            <div>
                <div className="screenTitle">
                    <h1>{RioResources.find("DeliveryEstimate").text}</h1>
                </div>

                <div className="form">
                    <div className="verticalFlex">
                        <label>{RioResources.find("DeliveryProvider").text}</label>
                        <RioSelect<SelectOption>
                            name="DeliveryProvider"
                            items={this.state.deliveryProviders}
                            value={!!this.state.selectedDeliveryProvider ? [this.state.selectedDeliveryProvider] : []}
                            keySelector={(item: SelectOption) => item.value}
                            descriptionSelector={(item: SelectOption) => item.text}
                            onSuggestionSelected={(value: SelectOption) => this.handleDeliveryProviderChanged(value)}
                        />
                    </div>

                    <div className="searchField verticalFlex">
                        <label>{RioResources.find("Postcode").text}</label>
                        <RioInput
                            name="Postcode"
                            step={1}
                            type="string"
                            value={this.state.deliveryEstimate.postcode || ''}
                            onChange={(e) => this.handlePostcodeChanged(e)}
                            validator={this.validator}
                            validations={{ required: true }}
                        />
                    </div>

                    <div className="searchField verticalFlex">
                        <label>{RioResources.find("NoOfCases").text}</label>
                        <RioInput
                            name="NoOfCases"
                            step={1}
                            type="number"
                            value={this.state.deliveryEstimate.caseQuantity || ''}
                            onChange={(e) => this.handleNoOfCasesChanged(e)}
                            validator={this.validator}
                            validations={{ required: true }}
                        />
                    </div>

                    <div className="horizontalFlex">
                        <button onClick={() => this.getEstimate()}>
                            {RioResources.find("GetEstimate").text}
                        </button>
                    </div>
                </div>

                <RioIfFunc condition={!this.state.deliveryEstimateResult} then={() => {
                    return (
                        <div className="bubble time-bubble">
                            <p>{RioResources.find("NoResultTryChangingFilters").text}</p>
                        </div>
                    );
                }} />

                <RioIfFunc condition={!!this.state.deliveryEstimateResult?.estimate?.deliveryMessage} then={() => {
                    return (
                        <div>
                            <div className="bubble time-bubble">
                                <h4>{this.state.deliveryEstimateResult.estimate.deliveryMessage}</h4>
                            </div>
                        </div>
                    );
                }} />

                <RioIfFunc condition={!!this.state.deliveryEstimateResult?.estimate} then={() => {
                    return (
                        <div className="bubble time-bubble">
                            <div className="productRow">
                                <label>{RioResources.find("DeliveryCostExcVAT").text}</label>
                                <p>{this.state.deliveryEstimateResult?.estimate ? "\u00A3" + (this.state.deliveryEstimateResult.estimate.deliveryCostGross + this.state.deliveryEstimateResult.surchargeGross).toFixed(2) : ""}</p>
                            </div>
                            <div className="productRow">
                                <label>{RioResources.find("DeliveryVAT").text}</label>
                                <p>{this.state.deliveryEstimateResult?.estimate ? "\u00A3" + (this.state.deliveryEstimateResult.estimate.deliveryVAT + this.state.deliveryEstimateResult.surchargeVAT).toFixed(2) : ""}</p>
                            </div>
                            <div className="productRow">
                                <label>{RioResources.find("DeliveryCostIncVAT").text}</label>
                                <p>{this.state.deliveryEstimateResult?.estimate ? "\u00A3" + (this.state.deliveryEstimateResult.estimate.deliveryCostGross + this.state.deliveryEstimateResult.estimate.deliveryVAT + this.state.deliveryEstimateResult.surchargeGross + this.state.deliveryEstimateResult.surchargeVAT).toFixed(2).toString() : ""}</p>
                            </div>
                        </div>
                    );
                }} otherwise={() => {
                    return RioResources.find("NoResultTryChangingFilters").text
                }} />

                <div className="alert-message alert-message-info">
                    <RioIfFunc condition={!!this.state.deliveryEstimateResult?.estimate?.deliveryNotes} then={() => {
                        return (
                            <div>
                                this.state.deliveryEstimateResult.estimate.deliveryNotes;
                            </div>
                        );
                    }} otherwise={() => {
                        return (
                            <div>
                                <h4>{RioResources.find("DeliveryInfo").text}</h4>
                                <p>
                                    Delivery estimates based upon orders being loaded on to a single vehicle.
                                </p>
                                <p>
                                    Orders will be processed on 4 Bank Holidays per year, Easter Monday, Early May,
                                    Late May (Summer) and August Bank Holidays and will be delivered on the next scheduled delivery day, as per the schedule above.
                                    Deliveries will also be made on these days within the M25 for orders received the previous working day unless advised otherwise.
                                </p>
                                <p>
                                    Orders not received by the order deadline will be deemed to have been received the following working day.
                                </p>
                                <p>
                                    The above delivery days do not apply for orders in excess of 90 dozen or requiring a booking which will be delivered at the earliest opportunity
                                </p>
                                <p>
                                    Additional charges may apply if delivery instructions incur {this.state.deliveryEstimateResult?.estimate?.deliveryProvider ? this.state.deliveryEstimateResult.estimate.deliveryProvider : ""} in any extra costs e.g. Specified vehicle size, two man delivery, out of hours delivery times or single products per pallet.
                                </p>
                            </div>
                        );
                    }} />
                </div>
            </div>
        );
    }

    handleDeliveryProviderChanged(selectedOption: SelectOption) {
        let deliveryEstimate = { 
            ...this.state.deliveryEstimate,
            organisationID: parseInt(selectedOption.value)
        };

        this.setState({
            deliveryEstimate: deliveryEstimate,
            selectedDeliveryProvider: selectedOption
        });
    }

    handlePostcodeChanged(event: React.ChangeEvent<HTMLInputElement>) {
        let deliveryEstimate = { 
            ...this.state.deliveryEstimate,
            postcode: event.target.value
        };

        this.setState({
            deliveryEstimate: deliveryEstimate
        });
    }

    handleNoOfCasesChanged(event: React.ChangeEvent<HTMLInputElement>) {
        let deliveryEstimate = { 
            ...this.state.deliveryEstimate,
            caseQuantity: parseInt(event.target.value)
        };

        this.setState({
            deliveryEstimate: deliveryEstimate
        });
    }

    getEstimate() {
        DeliveryEstimateApi.getDeliveryEstimate(this.state.deliveryEstimate, response => {
            if (response.success) {
                this.setState({
                    deliveryEstimateResult: response.item,
                    loading: false
                });
            } else {
                this.validator.clearModelErrors(this);
                this.validator.addModelErrors(this, response.errors);

                this.setState({
                    deliveryEstimateResult: null,
                    loading: false,
                    error: true,
                    errors: response.errors
                });
            }
        });
    }
}