import * as React from "react";
import { RouteComponentProps } from "react-router";
import { Spinner } from 'reactstrap';
import { RioCheckbox, RioForEach, RioIf, RioIfFunc, RioInput, RioModal, RioResources, RioValidator } from "rio.react.all";
import { UserApi } from "../apiLayer/UserApi";
import { UserApiModel } from "../apiModels/user/userApiModel";
import { SecurityGroupApiModel } from "../apiModels/user/userGroupApiModel";

interface IUserAddUpdateParams {
    userId: string;
}

interface IUserAddUpdateProps extends Partial<RouteComponentProps<IUserAddUpdateParams>> {
    userId: number;
    onClose: () => void;
}

interface IUserAddUpdateState {
    // Bindings
    user: UserApiModel;
    loading: boolean;
    saving: boolean;
    sendingReset: boolean;
    error: boolean;
    passwordResetSent: boolean;
    availableGroups: SecurityGroupApiModel[];
}

export class UserAddUpdateModal extends React.Component<IUserAddUpdateProps, IUserAddUpdateState> {
    constructor(props: Readonly<IUserAddUpdateProps>) {
        super(props);

        this.state = {
            user: null,
            loading: true,
            saving: false,
            sendingReset: false,
            error: false,
            passwordResetSent: false,
            availableGroups: []
        };
    }

    private validator = new RioValidator();

    componentDidMount() {
        this.getUser();
    }

    getUser() {
        if (this.props.userId === 0 || isNaN(this.props.userId)) {
            var newUser = new UserApiModel();
            newUser.securityGroups = [];
            newUser.userID = 0;

            this.setState({
                user: newUser
            });

            UserApi.getAvailableGroups(response => {
                this.setState({
                    availableGroups: response.item,
                    loading: false
                });
            });
        } else {
            UserApi.getUser(this.props.userId, response => {
                if (response.success) {
                    this.setState({
                        user: response.item
                    }, () => {
                        UserApi.getAvailableGroups(response => {
                            for (var i = 0; i < response.item?.length || 0; i++) {
                                for (var j = 0; j < this.state.user.securityGroups?.length || 0; j++) {
                                    if (this.state.user.securityGroups[j].securityGroupID === response.item[i].securityGroupID) {
                                        response.item[i].selected = true;
                                        break;
                                    }
                                }
                            }

                            this.setState({
                                availableGroups: response.item,
                                loading: false
                            });
                        });
                    });
                } else {
                    this.setState({
                        error: true,
                        loading: false
                    });
                }
            });
        }
    }

    render() {
        return (
            <RioModal visible={true} onCancel={() => this.handleModalCloseClicked()} className="large">
                <div className="userDetailsArea">
                    <RioIfFunc condition={!!this.state.loading} then={() => {
                        return (
                            <div className="spinner-container">
                                <Spinner />
                            </div>
                        );
                    }} otherwise={() => {
                        return (
                            <React.Fragment>
                                <div className="screenTitle">
                                    {this.renderTitle()}
                                </div>

                                <div className="form">
                                    <div className="horizontalFlex">
                                        <label className="mandatory">{RioResources.find("FirstName").text}</label>
                                        <RioInput
                                            name="FirstName"
                                            type="text"
                                            value={this.state.user ? this.state.user.firstName : ""}
                                            onChange={(e) => this.handleFirstNameChanged(e)}
                                            validator={this.validator}
                                            validations={{ required: true }}
                                        />
                                    </div>

                                    <div className="horizontalFlex">
                                        <label className="mandatory">{RioResources.find("LastName").text}</label>
                                        <RioInput
                                            name="LastName"
                                            type="text"
                                            value={this.state.user ? this.state.user.lastName : ""}
                                            onChange={(e) => this.handleLastNameChanged(e)}
                                            validator={this.validator}
                                            validations={{ required: true }}
                                        />
                                    </div>

                                    <div className="horizontalFlex">
                                        <label className="mandatory">{RioResources.find("Email").text}</label>
                                        <RioInput
                                            name="Email"
                                            type="text"
                                            value={this.state.user ? this.state.user.email : ""}
                                            onChange={(e) => this.handleEmailChanged(e)}
                                            validator={this.validator}
                                            validations={{ required: true }}
                                        />
                                    </div>

                                    <div className="horizontalFlex">
                                        <label>{RioResources.find("Locked").text}</label>
                                        <div className="userStatus">
                                            <RioCheckbox
                                                checked={this.state.user && this.state.user.locked}
                                                onChange={() => this.handleLockedChanged()}
                                            />
                                        </div>
                                    </div>

                                    <div className="horizontalFlex">
                                        <label>{RioResources.find("Active").text}</label>
                                        <div className="userStatus">
                                            <RioCheckbox
                                                checked={this.state.user && this.state.user.hasAccess}
                                                onChange={() => this.handleActiveChanged()}
                                            />
                                        </div>
                                    </div>

                                    <RioIf condition={this.state.availableGroups.length > 0} then={
                                        <div className="horizontalFlex userSecurityGroups">
                                            <label>{RioResources.find("RolePermissions").text}</label>
                                            <div className="verticalFlex userGroupCheckBoxes">
                                                <RioForEach items={this.state.availableGroups} perform={(item: SecurityGroupApiModel) => {
                                                    return (
                                                        <div className="horizontalFlex userGroupCheck" key={item.securityGroupID}>
                                                            <RioCheckbox
                                                                checked={item.selected}
                                                                onChange={() => this.handleGroupSelectedChanged(item)}
                                                                key={item.securityGroupID}
                                                            />

                                                            <label onClick={() => this.handleGroupSelectedChanged(item)}>
                                                                {item.name}
                                                            </label>
                                                        </div>
                                                    );
                                                }} />
                                            </div>
                                        </div>
                                    } />
                                </div>

                                <div className="horizontalFlex formButtons">
                                    {this.renderPasswordReset()}

                                    <div className="buttonHolder">
                                        <button onClick={() => this.cancel()}>
                                            {RioResources.find("Cancel").text}
                                        </button>
                                    </div>

                                    <div className="buttonHolder">
                                        <RioIf condition={this.state.saving} then={
                                            <Spinner />
                                        } otherwise={
                                            <button onClick={() => this.save()}>
                                                {RioResources.find("Save").text}
                                            </button>
                                        } />
                                    </div>
                                </div>
                            </React.Fragment>
                        );
                    }} />
                </div>
            </RioModal>
        );
    }

    renderTitle() {
        if (!this.state.user) {
            return "";
        }

        if (this.state.user.userID === 0) {
            return <h1>New User</h1>;
        }

        return <h1>Edit User</h1>;
    }

    renderPasswordReset() {
        if (this.state.passwordResetSent) {
            return (
                <p className="passwordResetSent">
                    {RioResources.find("PasswordResetSent").text}
                </p>
            );
        }

        if (!this.state.user || this.state.user.userID === 0 || this.state.loading) {
            return ""; // Can't reset password of a new user
        }

        return (
            <div className="buttonHolder">
                <RioIf condition={this.state.sendingReset} then={
                    <Spinner />
                } otherwise={
                    <button onClick={() => this.sendPasswordReset()}>
                        {RioResources.find("SendPasswordReset").text}
                    </button>
                } />
            </div>
        );
    }

    sendPasswordReset() {
        this.setState({
            sendingReset: true
        }, () => {
            UserApi.passwordReset(this.state.user.email, () => {
                this.setState({
                    sendingReset: false,
                    passwordResetSent: true
                });
            });
        });
    }

    cancel() {
        this.props.onClose();
    }

    save() {
        this.setState({
            saving: true
        }, () => {
            UserApi.postUser(this.state.user, response => {
                if (response.success) {
                    this.setState({
                        saving: false
                    }, () => {
                        this.props.onClose();
                    });
                } else {
                    this.validator.clearModelErrors(this);
                    this.validator.addModelErrors(this, response.errors);

                    this.setState({
                        error: true,
                        saving: false
                    });
                }
            });
        });
    }

    handleModalCloseClicked() {
        this.props.onClose();
    }

    handleFirstNameChanged(event: React.ChangeEvent<HTMLInputElement>) {
        let user = {
            ...this.state.user,
            firstName: event.target.value
        };

        this.setState({ user: user });
    }

    handleLastNameChanged(event: React.ChangeEvent<HTMLInputElement>) {
        let user = {
            ...this.state.user,
            lastName: event.target.value
        };

        this.setState({ user: user });
    }

    handleEmailChanged(event: React.ChangeEvent<HTMLInputElement>) {
        let user = {
            ...this.state.user,
            email: event.target.value
        };

        this.setState({ user: user });
    }

    handleLockedChanged() {
        let user = {
            ...this.state.user,
            locked: !this.state.user.locked
        };

        this.setState({ user: user });
    }

    handleActiveChanged() {
        let user = {
            ...this.state.user,
            hasAccess: !this.state.user.hasAccess
        };

        this.setState({ user: user });
    }

    handleGroupSelectedChanged(item: SecurityGroupApiModel) {
        let group = this.state.availableGroups.find(g => g.securityGroupID === item.securityGroupID);
        group = {
            ...group,
            selected: !group.selected
        };

        const availableGroups = this.state.availableGroups.map((item) => item.securityGroupID === group.securityGroupID ? group : item);

        let securityGroups = [ ...this.state.user.securityGroups ];

        let existingIndex = securityGroups.findIndex(sg => sg.securityGroupID === item.securityGroupID)
        if (existingIndex > -1) {
            // If item is already selected, deselect it
            securityGroups.splice(existingIndex, 1)
        } else {
            securityGroups.push(item);
        }

        const user: UserApiModel = {
            ...this.state.user,
            securityGroups: securityGroups
        };

        this.setState({
            user: user,
            availableGroups: availableGroups
        })
    }
}

