import * as React from "react";
import { Redirect, Route, Switch } from "react-router";
import { ApiErrorHandler, IRioResources, RioApiGet, RioIf, setRioResources } from "rio.react.all";
import { IContextApiModel } from "./apiModels/RioIContextApiModel";
import Layout from './components/Layout';
import { HttpErrorHelper } from "./helpers/RioHttpErrorHelper";
import { ErrorModal } from "./modals/RioErrorModal";
import { GlobalContext } from "./RioContext";
import { CustomerCategoryDistribution } from "./views/CustomerCategoryDistribution";
import { CustomerSearch } from "./views/CustomerSearch";
import { DeliveryEstimate } from "./views/DeliveryEstimate";
import { GrossMarginReport } from "./views/GrossMarginReport";
import { Login } from "./views/Login";
import { PasswordReset } from "./views/PasswordReset";
import { ProductCodeGenerator } from "./views/ProductCodeGenerator";
import { Repair } from "./views/Repair";
import { Users } from "./views/Users";
import { FinancialYears } from "./views/FinancialYears";

interface IAppState {
    RioResourcesLoaded: boolean;
    globals: IContextApiModel;
    responseError: string;
    unauthorised: boolean;
}

export default class App extends React.Component<{}, IAppState> {
    constructor(props: {}) {
        super(props);

        ApiErrorHandler.onUnauthorised = () => {
            this.setState({ unauthorised: true });

            if (window.location.pathname !== '/' &&
                window.location.pathname.toLowerCase().indexOf("/reset") === -1) {
                window.location.href = "/";
            }
        };

        this.state = {
            RioResourcesLoaded: false,
            globals: null,
            responseError: null,
            unauthorised: false
        };

        ApiErrorHandler.onUnauthorised = () => {
            this.setState({ unauthorised: true });
        };
    }

    alreadyRequestedLogout: boolean = false;

    componentDidMount(): void {
        RioApiGet.asPromise<IContextApiModel>("/api/context/global")
            .then(response => {
                this.setState({ globals: response });
            })
            .catch(response => {
                var error = HttpErrorHelper.handleHttpError(response, this, null); //handles validator errors if validator passed
                this.setState({ responseError: error });
            });

        RioApiGet.asPromise<IRioResources>("/api/context/RioResources")
            .then(response => {
                setRioResources(response);
                this.setState({ RioResourcesLoaded: true });
            })
            .catch(response => {
                var error = HttpErrorHelper.handleHttpError(response, this, null); //handles validator errors if validator passed
                this.setState({ responseError: error });
            });
    }

    render() {
        if (!this.state.RioResourcesLoaded || !this.state.globals) {
            return <p className="rootMessage">Loading...</p>;
        }

        if (this.state.unauthorised || !this.state.globals?.user) {
            return (
                <GlobalContext.Provider value={this.state.globals}>
                    <Switch>
                        <Route exact path="/Reset/:key" component={PasswordReset} />
                        <Route exact path="/Logout" render={() => this.logout() } />
                        <Route exact path="" component={Login} />
                        <Redirect to="" />
                    </Switch>
                </GlobalContext.Provider>
            );
        }

        if (this.state.responseError) {
            return (
                <div className="rootMessage">
                    <h1>Server error</h1>
                    <p>Unable to load resources. This is usually due to an internal server error. If the problem persists please contact support.</p>
                </div>
            );
        }

        return (
            <GlobalContext.Provider value={this.state.globals}>
                <Layout>
                    <Switch>
                        <Route exact path="/Login" component={Login} />
                        <Route exact path="/Logout" render={() => this.logout() } />

                        <Route exact path="/DistributionGraph" component={CustomerCategoryDistribution} />
                        <Route exact path="/DeliveryEstimate" component={DeliveryEstimate} />
                        <Route exact path="/ProductCodeGenerator" component={ProductCodeGenerator} />
                        <Route exact path="/GrossMarginReport" component={GrossMarginReport} />
                        <Route exact path="/Repair" component={Repair} />
                        <Route exact path="/Users" component={Users} />
                        <Route exact path="/FinancialYears" component={FinancialYears} />
                        <Route exact path="/" component={CustomerSearch} />
                        <Redirect to="" />
                    </Switch>
                </Layout>

                <RioIf condition={!!this.state.responseError} then={
                    <ErrorModal text={this.state.responseError} onErrorModalClosed={() => this.setState({ responseError: null })} />
                } />
            </GlobalContext.Provider>
        );
    }

    logout() {
        if (!this.alreadyRequestedLogout) {
            var submit = new RioApiGet("/home/Logout");
            submit.onDone = (response) => {
                window.location.href = "/";
                this.setState({ unauthorised: true });
            };
            submit.send();
            this.alreadyRequestedLogout = true;
        }
        return "";
    }
}