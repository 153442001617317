import * as React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { RioResources, RioUnsafe, RioUserDropDownMenu } from "rio.react.all";
import { IContextApiModel } from '../apiModels/RioIContextApiModel';
import { RioRoutedSideMenu } from "../components/SideMenu";
import { GlobalContext } from '../RioContext';

interface ILayoutProps extends Partial<RouteComponentProps> {
}

interface ILayoutState {
    pinnedMenuItem: string;
    showUserDropdownMenu: boolean;
}

export default class Layout extends React.Component<ILayoutProps, ILayoutState> {
    constructor(props: Readonly<ILayoutProps>) {
        super(props);

        this.state = {
            pinnedMenuItem: localStorage.getItem("pinnedMenuItem"),
            showUserDropdownMenu: false,
        };
}
    public render() {
        return (
            <React.Fragment>
                <header>
                    <Link to="/" className="logo">
                        <img src="/images/MrWheelerLogo.png" alt="Mr Wheeler Wine Logo" />
                    </Link>

                    <RioRoutedSideMenu 
                        {...this.props} 
                        menuEndpoint="/api/context/menu/1" 
                        pinnedMenuItem={this.state.pinnedMenuItem} 
                        setPinnedMenuItem={(currentMenuItem: string) => this.setPinnedMenuItem(currentMenuItem)}
                    />

                    <GlobalContext.Consumer>
                        {(globals: IContextApiModel) => (
                            <div id="userDropdown" className={"user" + (this.state.showUserDropdownMenu ? " is-active" : "")} onClick={(e) => this.handleUserDropdownClicked(e)}>
                                <div className="header-user-user-main">
                                    <span className="initials">
                                        {globals.user.firstName.charAt(0)}{globals.user.lastName.charAt(0)}
                                    </span>

                                    <p className="details">
                                        <span className="name">{globals.user.firstName} {globals.user.lastName}</span>
                                    </p>

                                    <RioUnsafe html={RioResources.find("Dropdown").image} />
                                </div>

                                <RioUserDropDownMenu menuItemEndpoint="/api/context/menu/2" show={this.state.showUserDropdownMenu} />
                            </div>
                        )}
                    </GlobalContext.Consumer>
                </header>

                <main className={!!this.state.pinnedMenuItem ? "pinned" : null}>
                    {this.props.children}
                </main>

                <footer>
                    <div className="poweredBy">
                        <a href="https://www.rioit.co.uk" target="_blank" rel="noopener noreferrer" className="footer-nav-item-link-powered js-button-link">
                            {RioResources.find("PoweredBy").text}
                            <img src="/images/rio-logo.png" className="footer-nav-item-image-powered" alt="Rio" />
                        </a>
                    </div>

                    <GlobalContext.Consumer>
                        {(globals: IContextApiModel) => (
                            <div>
                                {globals.version} &copy; {RioResources.find("PoweredByCompanyName").text} {RioResources.find("AllRightsReserved").text}.
                            </div>
                        )}
                    </GlobalContext.Consumer>
                </footer>
            </React.Fragment>
        );
    }

    setPinnedMenuItem(currentMenuItem?: string): void {
        this.setState({
            pinnedMenuItem: currentMenuItem
        }, () => {
            if (!!this.state.pinnedMenuItem) {
                localStorage.setItem("pinnedMenuItem", currentMenuItem);
            } else {
                localStorage.removeItem("pinnedMenuItem");
            }
        });
    }

    handleUserDropdownClicked(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        this.setState({
            showUserDropdownMenu: !this.state.showUserDropdownMenu
        });
    }
}