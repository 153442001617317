import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Spinner } from 'reactstrap';
import { IGridViewColumn, RioApiGet, RioApiPost, RioDatePicker, RioFinancial, RioGridView, RioIf, RioResources, RioValidator } from "rio.react.all";
import { GrossMarginReportApiModel } from '../apiModels/Report/GrossMarginReportApiModel';
import { GrossMarginReportCriteriaApiModel } from '../apiModels/Report/GrossMarginReportCriteriaApiModel';
import { RioSelect } from '../components/Select';
import { HttpErrorHelper } from '../helpers/RioHttpErrorHelper';
import { SelectOption } from '../helpers/RioSelectOption';
import { ErrorModal } from '../modals/RioErrorModal';

interface GrossMarginReportProps extends Partial<RouteComponentProps> {
}

interface GrossMarginReportState {
    searchParams: GrossMarginReportCriteriaApiModel;
    // UI State
    loading: boolean;
    downloading: boolean;
    items: GrossMarginReportApiModel[];
    availableSalesChannels: SelectOption[];
    selectedSalesChannels: SelectOption[];
    responseError: string;
}

export class GrossMarginReport extends React.Component<GrossMarginReportProps, GrossMarginReportState> {
    constructor(props: GrossMarginReportProps) {
        super(props);

        this.state = {
            loading: false,
            downloading: false,
            items: [],
            searchParams: new GrossMarginReportCriteriaApiModel(),
            availableSalesChannels: [],
            selectedSalesChannels: [],
            responseError: null
        };
    }

    private validator = new RioValidator();
    private dateFromDatePicker = React.createRef<RioDatePicker>();
    private dateToDatePicker = React.createRef<RioDatePicker>();

    columns: Array<IGridViewColumn<GrossMarginReportApiModel>> = [
        { title: RioResources.find("SalesChannel").text, value: (item) => item.salesChannelName },
        { title: RioResources.find("Brand").text, value: (item) => item.brandName },
        { title: RioResources.find("TotalSales").text, value: (item) => "", displayValue: (item) => <RioFinancial value={item.totalSales} currencySymbol="&amp;pound;" /> },
        { title: RioResources.find("TotalCosts").text, value: (item) => "", displayValue: (item) => <RioFinancial value={item.totalCosts} currencySymbol="&amp;pound;" /> },
        { title: RioResources.find("GrossProfit").text, value: (item) => "", displayValue: (item) => <RioFinancial value={item.grossProfit} currencySymbol="&amp;pound;" /> },
        { title: RioResources.find("GrossMargin").text, value: (item) => "", displayValue: (item) => item.grossMargin.toFixed(2) + "%" }
    ];

    componentDidMount() {
        RioApiGet.asPromise<SelectOption[]>("/api/report/availableSalesChannels")
            .then(response => {
                let searchParams = {
                    ...this.state.searchParams,
                    salesChannelIDs: response.map(v => v.value)
                };

                this.setState({
                    availableSalesChannels: response,
                    selectedSalesChannels: response,
                    searchParams: searchParams
                });
            })
            .catch((response) => {
                var error = HttpErrorHelper.handleHttpError(response, this, null); //handles validator errors if validator passed
                this.setState({
                    availableSalesChannels: [],
                    selectedSalesChannels: [],
                    responseError: error
                });
            });
    }

    searchClicked() {
        this.setState({
            loading: true
        }, () => {
            RioApiPost.asPromise<GrossMarginReportApiModel[]>("/api/report/grossMargin", this.state.searchParams)
                .then(response => {
                    this.setState({
                        items: response,
                        loading: false
                    });
                })
                .catch((response) => {
                    var error = HttpErrorHelper.handleHttpError(response, this, null); //handles validator errors if validator passed
                    this.setState({ responseError: error });
                });
        });
    }

    render() {
        return (
            <div className="GrossMarginReport">
                <div className="screenTitle">
                    <h1>{RioResources.find("GrossMarginReport").text}</h1>
                </div>

                <div className="searchArea">
                    <div className="verticalFlex">
                        <div className="searchField">
                            <label>{RioResources.find("SalesChannels").text}</label>
                            <RioSelect<SelectOption>
                                name="SalesChannel"
                                className="sales-channel"
                                items={this.state.availableSalesChannels}
                                value={this.state.selectedSalesChannels}
                                keySelector={(item: SelectOption) => { return item.value }}
                                descriptionSelector={(item: SelectOption) => { return item.text }}
                                onSuggestionSelected={(value: SelectOption) => { this.handleSalesChannelSelected(value) }}
                                isMultiSelect={true}
                            />
                        </div>

                        <div className="searchField">
                            <label>{RioResources.find("DateFrom").text}</label>
                            <RioDatePicker
                                ref={this.dateFromDatePicker}
                                name="DateFrom"
                                validator={this.validator}
                                from={this.state.searchParams.dateFrom}
                                onChange={(date: string) => this.handleSearchParamValueChanged("dateFrom", date)}
                                minDate="2017-07-01"
                            />
                        </div>

                        <div className="searchField">
                            <label>{RioResources.find("DateTo").text}</label>
                            <RioDatePicker
                                ref={this.dateToDatePicker}
                                name="DateTo"
                                validator={this.validator}
                                from={this.state.searchParams.dateTo}
                                onChange={(date: string) => this.handleSearchParamValueChanged("dateTo", date)}
                            />
                        </div>
                    </div>
                </div>

                <div className="horizontalFlex formButtons">
                    <button onClick={() => this.clear()}>
                        {RioResources.find("Clear").text}
                    </button>

                    <div className="buttonHolder">
                        <RioIf condition={this.state.loading} then={
                            <Spinner />
                        } otherwise={
                            <button onClick={() => this.searchClicked()}>
                                {RioResources.find("RunReport").text}
                            </button>
                        } />
                    </div>
                </div>

                <RioGridView<GrossMarginReportApiModel>
                    noResults={RioResources.find("NoResultsFound")}
                    columns={this.columns}
                    items={this.state.items}
                    rowKey={(item: GrossMarginReportApiModel) => item.salesChannelID}
                />

                <RioIf condition={!!this.state.responseError} then={
                    <ErrorModal text={this.state.responseError} onErrorModalClosed={() => this.setState({ responseError: null })} />
                } />
            </div>
        );
    }

    clear() {
        let searchParams = new GrossMarginReportCriteriaApiModel();
        searchParams.salesChannelIDs = this.state.availableSalesChannels.map(v => v.value);

        this.setState({
            selectedSalesChannels: this.state.availableSalesChannels,
            searchParams: searchParams,
            items: []
        });
    }

    handleSalesChannelSelected(item: SelectOption) {
        let values = [ ...this.state.selectedSalesChannels ];

        let existingIndex = values.indexOf(item)
        if (existingIndex > -1) {
            // If item is already selected, deselect it
            values.splice(existingIndex, 1)
        } else {
            values.push(item);
        }

        let searchParams = {
            ...this.state.searchParams,
            salesChannelIDs: values.map(v => v.value)
        };

        this.setState({
            selectedSalesChannels: values,
            searchParams: searchParams
        });
    }

    handleSearchParamValueChanged(key: string, value: string) {
        let searchParams = {
            ...this.state.searchParams,
            [key]: value
        };

        this.setState({ searchParams: searchParams });
    }
}