import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Spinner } from "reactstrap";
import { RioForEach, RioApiGet, RioMenuItemApiModel, RioToggleSwitch, RioIfFunc } from "rio.react.all";
import { RioSideMenuItem } from "./SideMenuItem";
import { RioSideMenuSubItem } from "./SideMenuSubItem";

interface ISideMenuParams {

}

interface ISideMenuProps extends RouteComponentProps<ISideMenuParams> {
    menuEndpoint: string;
    pinnedMenuItem: string;

    setPinnedMenuItem: (currentMenuItem?: string) => void;
}

interface ISideMenuState {
    loading: boolean;
    open: boolean;
    menuItems: Array<RioMenuItemApiModel>;
    currentMenuItem?: RioMenuItemApiModel;
}

export class RioSideMenu extends React.Component<ISideMenuProps, ISideMenuState> {
    constructor(props: Readonly<ISideMenuProps>) {
        super(props);

        this.state = {
            loading: false,
            open: !!this.props.pinnedMenuItem,
            menuItems: []
        };
    }

    render() {
        let classes = ["sideMenu"];
        if (!!this.props.pinnedMenuItem) classes.push('pinned');
        if (this.state.open) classes.push('open');

        return (
            <nav className={classes.join(' ')}>
                <RioIfFunc condition={this.state.loading} then={() => {
                    return <Spinner />
                }} otherwise={() => {
                    return (
                        <React.Fragment>
                            <div className="sideMenuPin">
                                <RioToggleSwitch id="pinMenu" className="" on={!!this.props.pinnedMenuItem} onClick={() => this.handlePinClick()} />
                            </div>

                            <div className="sideMenuMainItems">
                                <RioForEach items={this.state.menuItems} perform={(item: RioMenuItemApiModel) => {
                                    return <RioSideMenuItem item={item} menuItem={item.resourceName} active={item === this.state.currentMenuItem} itemClicked={(item) => this.handleSideMenuItemClicked(item)} itemMiddleClicked={(item) => this.handleSideMenuItemMiddleClicked(item)} key={item.menuItemID} />;
                                }} />
                            </div>

                            <div className="sideMenuSubItems">
                                {!!this.state.currentMenuItem &&
                                    <RioForEach items={this.state.currentMenuItem.childItems} perform={(item: RioMenuItemApiModel) => {
                                        return <RioSideMenuSubItem item={item} itemClicked={(item) => this.handleSideMenuSubItemClicked(item)} itemMiddleClicked={(item) => this.handleSideMenuSubItemMiddleClicked(item)} key={item.menuItemID} />;
                                    }} />
                                }
                            </div>
                        </React.Fragment>
                    );
                }} />
            </nav>
        );
    }

    componentDidMount(): void {
        this.loadMenu();

        // TODO: Replace this JQuery

        // $(".mobileSideMenuBurger").off("click").click(function () {
        //     $(".mobileSideMenuBurger").toggleClass("mobileMenuOpen");
        //     $(".sideMenu").toggleClass("mobileMenuOpen");
        // });
    }

    loadMenu() {
        const call = new RioApiGet<Array<RioMenuItemApiModel>>(this.props.menuEndpoint);

        this.setState({
            loading: true
        }, () => {
            call.onDone = response => {
                if (response.success) {
                    this.setState({
                        menuItems: response.item,
                        loading: false
                    }, () => {
                        if (!this.props.pinnedMenuItem) {
                            return;
                        }

                        var pinnedMenuItem = this.props.pinnedMenuItem;

                        if (!pinnedMenuItem) {
                            return;
                        }

                        var currentMenuItem = this.state.menuItems.find(function (element) {
                            return element.resourceName === pinnedMenuItem;
                        });

                        this.setState({
                            currentMenuItem: currentMenuItem
                        });
                    });
                }
            }

            call.send();
        });
    }

    handlePinClick() {
        this.props.setPinnedMenuItem(!!this.props.pinnedMenuItem ? null : this.state.currentMenuItem?.resourceName);
    }

    handleSideMenuItemClicked(item: RioMenuItemApiModel): void {
        if (!!item.controllerAction) {
            this.props.history.push(item.controllerAction.startsWith('/') ? item.controllerAction : '/' + item.controllerAction);
            return;
        }

        let newCurrentMenuItem: RioMenuItemApiModel = item;

        if (this.state.currentMenuItem === newCurrentMenuItem) {
            if (!!this.props.pinnedMenuItem) {
                return;
            }

            newCurrentMenuItem = null;
        }

        this.setState({
            open: newCurrentMenuItem !== null,
            currentMenuItem: newCurrentMenuItem
        }, () => {
            if (!!this.props.pinnedMenuItem) {
                this.props.setPinnedMenuItem(this.state.currentMenuItem?.resourceName);
            }
        });
    }

    handleSideMenuItemMiddleClicked(item: RioMenuItemApiModel): void {
        if (!!item.controllerAction) {
            window.open(RioSideMenu.generateUrl(item), "_blank");
        }
    }

    handleSideMenuSubItemClicked(item: RioMenuItemApiModel): void {
        if (!!item.controllerAction) {
            this.props.history.push(item.controllerAction.startsWith('/') ? item.controllerAction : '/' + item.controllerAction);

            if (!this.props.pinnedMenuItem) {
                this.setState({
                    open: false,
                    currentMenuItem: null
                });
            }
        }
    }

    handleSideMenuSubItemMiddleClicked(item: RioMenuItemApiModel): void {
        if (!!item.controller) {
            window.open(RioSideMenu.generateUrl(item), "_blank");
        }
    }

    static generateUrl(item: RioMenuItemApiModel): string {
        if (!item.controller) {
            return "#";
        }

        return "/" + item.controller.replace("Controller", "") + "/" + (!!item.controllerAction ? item.controllerAction : "");
    }
}

export const RioRoutedSideMenu = withRouter(RioSideMenu);