import { BaseApi } from "./BaseApi";
import { ApiResponse } from "rio.react.all";
import { SelectOption } from "../helpers/RioSelectOption";
import { DeliveryEstimateApiModel } from "../apiModels/DeliveryEstimate/deliveryEstimateApiModel";
import { DeliveryEstimateResultApiModel } from "../apiModels/DeliveryEstimate/deliveryEstimateResultApiModel";

export class DeliveryEstimateApi {

    static getDeliveryProviders(ondone: (response: ApiResponse<SelectOption[]>) => any) {
        BaseApi.get("/api/deliveryestimate/getdeliveryproviders", ondone);
    }

    static getDeliveryEstimate(deliveryEstimateApiModel: DeliveryEstimateApiModel,
        ondone: (response: ApiResponse<DeliveryEstimateResultApiModel>) => any) {
        BaseApi.post("/api/deliveryestimate/getdeliveryestimate", deliveryEstimateApiModel, ondone);
    }
}