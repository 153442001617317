import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Spinner } from 'reactstrap';
import { IGridViewColumn, RioApiGet, RioApiPost, RioGridView, RioIf, RioInput, RioResources, RioValidator } from "rio.react.all";
import { CustomerApiModel } from '../apiModels/customer/customerApiModel';
import { CustomerSearchApiModel } from '../apiModels/customer/customerSearchApiModel';
import { RioSelect } from '../components/Select';
import { HttpErrorHelper } from '../helpers/RioHttpErrorHelper';
import { SelectOption } from '../helpers/RioSelectOption';
import { ErrorModal } from '../modals/RioErrorModal';


interface CustomerSearchProps extends Partial<RouteComponentProps> {
}

interface CustomerSearchSearchState {
    searchParams: CustomerSearchApiModel;
    // UI State
    loading: boolean;
    downloading: boolean;
    items: CustomerApiModel[];
    availableBrands: SelectOption[];
    selectedBrand: SelectOption;
    availableCategories: SelectOption[];
    selectedCategories: SelectOption[];
    responseError: string;
}

export class CustomerSearch extends React.Component<CustomerSearchProps, CustomerSearchSearchState> {
    constructor(props: CustomerSearchProps) {
        super(props);

        this.state = {
            loading: false,
            downloading: false,
            items: [],
            searchParams: new CustomerSearchApiModel(),
            availableBrands: [],
            selectedBrand: null,
            availableCategories: [],
            selectedCategories: [],
            responseError: null
        };
    }

    private validator = new RioValidator();

    columns: Array<IGridViewColumn<CustomerApiModel>> = [
        { title: RioResources.find("AccountNos").text, value: (item) => item.accountNos },
        { title: RioResources.find("FirstName").text, value: (item) => item.firstName },
        { title: RioResources.find("LastName").text, value: (item) => item.lastName },
        { title: RioResources.find("Address").text, value: (item) => item.addressLine1 },
        {
            title: RioResources.find("Email").text,
            value: (item) => item.email,
            displayValue: (item) => item.email !== null && item.email !== "" ? (<a href={"mailto: " + item.email}>{item.email}</a>) : ""
        },
        { title: RioResources.find("Mobile").text, value: (item) => item.mobilePhone },
        { title: RioResources.find("Category").text, value: (item) => item.category }
    ];

    //actions: Array<IGridViewAction<CustomerApiModel>> = [
    //    {
    //        resource: RioResources.find("Customer"),
    //        onClick: (item) => { this.handleDetailsClicked(item) }, default: true,
    //        hasPermission: true
    //    }
    //];

    componentDidMount() {
        RioApiGet.asPromise<SelectOption[]>("/api/customersearch/availablebrands/false")
            .then(response => {
                this.setState({
                    availableBrands: response,
                    selectedBrand: response.find(m => m.value === this.state.searchParams.brandId.toString())
                });
            })
            .catch((response) => {
                var error = HttpErrorHelper.handleHttpError(response, this, null); //handles validator errors if validator passed

                this.setState({
                    availableBrands: [],
                    selectedBrand: null,
                    responseError: error
                });
            });

        RioApiGet.asPromise<SelectOption[]>("/api/customersearch/availablecategories")
            .then(response => {
                let searchParams = { ...this.state.searchParams };
                searchParams.categoryIDs = [];

                var selected = [];

                for (var i = 0; i < response.length; i++) {
                    var value = response[i].value;
                    selected.push(response[i]);
                    searchParams.categoryIDs.push(parseInt(value));
                }

                this.setState({
                    searchParams: searchParams,
                    availableCategories: response,
                    selectedCategories: selected
                });
            })
            .catch((response) => {
                var error = HttpErrorHelper.handleHttpError(response, this, null); //handles validator errors if validator passed

                this.setState({
                    availableCategories: [],
                    selectedCategories: [],
                    responseError: error
                });
            });
    }

    componentWillUnmount() {
        this.setState = (_, __) => { return; };
    }

    searchClicked() {
        this.setState({
            loading: true
        }, () => {
            RioApiPost.asPromise<CustomerApiModel[]>("/api/customersearch/search", this.state.searchParams)
                .then(response => {
                    this.setState({
                        items: response,
                        loading: false
                    });
                })
                .catch((response) => {
                    var error = HttpErrorHelper.handleHttpError(response, this, null); //handles validator errors if validator passed
                    this.setState({ responseError: error });
                });
        });
    }

    downloadClicked() {
        this.setState({
            downloading: true
        }, () => {
            const call = new RioApiPost<null>("/api/customersearch/downloadcustomers", this.state.searchParams);
            call.onDone = response => {
                this.setState({ downloading: false }, () => {
                    if (!response.success) {
                        var error = HttpErrorHelper.handleHttpError(response, this, null); //handles validator errors if validator passed
                        this.setState({ responseError: error });
                    }
                });
            }
            call.sendAndGetFile();
        });
    }

    render() {
        return (
            <div className="customerSearch">
                <div className="screenTitle">
                    <h1>{RioResources.find("CustomerSearch").text}</h1>
                </div>

                <div className="searchArea">
                    <div className="verticalFlex">
                        <div className="searchField">
                            <label>{RioResources.find("AccountNo").text}</label>
                            <RioInput
                                name="AccountNo"
                                type="text"
                                value={this.state.searchParams.accountNo ?? ""}
                                onChange={(e) => this.handleAccountNoChanged(e)} validator={this.validator}
                                validations={{ required: false }}
                            />
                        </div>

                        <div className="searchField">
                            <label>{RioResources.find("Brand").text}</label>
                            <RioSelect<SelectOption>
                                name="Brand"
                                items={this.state.availableBrands}
                                value={!!this.state.selectedBrand ? [this.state.selectedBrand] : []}
                                keySelector={(item: SelectOption) => { return item.value }}
                                descriptionSelector={(item: SelectOption) => { return item.text }}
                                onSuggestionSelected={(value: SelectOption) => { this.handleBrandChanged(value) }}
                            />
                        </div>
                    </div>

                    <div className="verticalFlex">
                        <div className="searchField">
                            <label>{RioResources.find("FirstName").text}</label>
                            <RioInput
                                name="FirstName"
                                type="text"
                                value={this.state.searchParams.firstName ?? ""}
                                onChange={(e) => this.handleFirstNameChanged(e)}
                                validator={this.validator}
                                validations={{ required: false }}
                            />
                        </div>

                        <div className="searchField">
                            <label>{RioResources.find("Categories").text}</label>
                            <RioSelect<SelectOption>
                                name="Categories"
                                items={this.state.availableCategories}
                                value={this.state.selectedCategories}
                                keySelector={(item: SelectOption) => { return item.value }}
                                descriptionSelector={(item: SelectOption) => { return item.text }}
                                onSuggestionSelected={(value: SelectOption) => { this.handleCategorySelected(value) }}
                                isMultiSelect={true}
                            />
                        </div>
                    </div>
                    <div className="verticalFlex">
                        <div className="searchField">
                            <label>{RioResources.find("LastName").text}</label>
                            <RioInput
                                name="LastName"
                                type="text"
                                value={this.state.searchParams.lastName ?? ""}
                                onChange={(e) => this.handleLastNameChanged(e)}
                                validator={this.validator}
                                validations={{ required: false }}
                            />
                        </div>
                    </div>
                </div>
                <div className="horizontalFlex formButtons">
                    <button onClick={() => this.clear()}>{RioResources.find("Clear").text}</button>
                    <div className="buttonHolder">
                        <RioIf condition={this.state.loading} then={
                            <Spinner />
                        } otherwise={
                            <button onClick={() => this.searchClicked()}>{RioResources.find("Search").text}</button>
                        } />
                    </div>

                    <div className="buttonHolder">
                        <RioIf condition={this.state.downloading} then={
                            <Spinner />
                        } otherwise={
                            <button onClick={() => this.downloadClicked()}>{RioResources.find("Download").text}</button>
                        } />
                    </div>
                </div>

                <RioGridView<CustomerApiModel>
                    noResults={RioResources.find("NoResultsFound")}
                    columns={this.columns}
                    //actions={this.actions}
                    items={this.state.items}
                    rowKey={(item: CustomerApiModel) => item.customerID}
                />

                <RioIf condition={!!this.state.responseError} then={
                    <ErrorModal text={this.state.responseError} onErrorModalClosed={() => this.setState({ responseError: null })} />
                } />
            </div>
        );
    }

    clear() {
        const searchParams = new CustomerSearchApiModel();
        const selected = [];

        for (var i = 0; i < this.state.availableCategories.length; i++) {
            var value = this.state.availableCategories[i].value;
            selected.push(this.state.availableCategories[i]);
            searchParams.categoryIDs.push(parseInt(value));
        }

        this.setState({
            selectedCategories: selected,
            selectedBrand: this.state.availableBrands.find(m => m.value === this.state.searchParams.brandId.toString()),
            items: []
        });
    }

    handleAccountNoChanged(event: React.ChangeEvent<HTMLInputElement>) {
        const searchParams = {
            ...this.state.searchParams,
            accountNo: event.target.value
        };

        this.setState({ searchParams: searchParams });
    }

    handleBrandChanged(selectedOption: SelectOption) {
        if (this.state.selectedBrand?.value !== selectedOption.value) {
            const searchParams = {
                ...this.state.searchParams,
                brandId: parseInt(selectedOption.value)
            };

            this.setState({
                selectedBrand: selectedOption,
                searchParams: searchParams
            });
        }
    }

    handleFirstNameChanged(event: React.ChangeEvent<HTMLInputElement>) {
        const searchParams = { 
            ...this.state.searchParams,
            firstName: event.target.value
        };

        this.setState({ searchParams: searchParams });
    }

    handleLastNameChanged(event: React.ChangeEvent<HTMLInputElement>) {
        const searchParams = { 
            ...this.state.searchParams,
            lastName: event.target.value
        };
        
        this.setState({ searchParams: searchParams });
    }

    handleCategorySelected(value: SelectOption) {
        const categoryIDs = [...this.state.searchParams.categoryIDs];
        const selectedCategories = [...this.state.selectedCategories];
        const id = parseInt(value.value);
        let found = false;

        for (var i = 0; i < categoryIDs.length; i++) {
            if (categoryIDs[i] === id) {
                categoryIDs.splice(i, 1);
                selectedCategories.splice(i, 1);
                found = true;
                break;
            }
        }

        if (!found) {
            selectedCategories.push(value);
            categoryIDs.push(id);
        }

        this.setState({
            selectedCategories: selectedCategories,
            searchParams: {
                ...this.state.searchParams,
                categoryIDs: categoryIDs
            }
        });
    }

    //handleDetailsClicked(item: CustomerApiModel): void {
    //this.props.history.push("/customers/" + item.customerID);
    //}
}