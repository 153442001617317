export class BasePasswordResetViewModel {
    constructor() {
        this.key = "";
        this.password = "";
        this.verifyPassword = "";
    }

    key: string;
    password: string;
    verifyPassword: string;
}