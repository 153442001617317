import * as React from "react";
import { RioForEach, RioResources, RioMenuItemApiModel } from "rio.react.all";


interface ISideMenuSubItemProps {
    item: RioMenuItemApiModel;

    itemClicked: (item: RioMenuItemApiModel) => void;
    itemMiddleClicked?: (item: RioMenuItemApiModel) => void;
}

export class RioSideMenuSubItem extends React.Component<ISideMenuSubItemProps> {
    constructor(props: ISideMenuSubItemProps) {
        super(props);

        this.handleMouseUp = this.handleMouseUp.bind(this);
    }
    
    render() {
        let resource = RioResources.find(this.props.item.resourceName);
        if (!resource) return null;

        if (!!this.props.item.childItems && this.props.item.childItems.length > 0) {
            return (
                <React.Fragment>
                    <div className="sideMenuSubHeading">
                        <span className="text">{resource.text}</span>
                    </div>

                    <RioForEach items={this.props.item.childItems} perform={(item: RioMenuItemApiModel) => {
                        return <RioSideMenuSubItem item={item} itemClicked={(item) => this.props.itemClicked(item)} itemMiddleClicked={this.props.itemMiddleClicked} key={item.menuItemID} />;
                    }} />
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <div className="sideMenuSubItem" onClick={() => this.props.itemClicked(this.props.item)} onMouseUp={this.handleMouseUp}>
                        <span className="text">{resource.text}</span>
                        <div className="sideMenuSubItemSelectedIndicator"></div>
                    </div>
					
                    <div className="sideMenuSubItemSeperator"></div>
                </React.Fragment>
            );
        }
    }

    handleMouseUp(e: React.MouseEvent) {
        if (e.button === 1 && !!this.props.itemMiddleClicked) {
            e.preventDefault();
            this.props.itemMiddleClicked(this.props.item);
        }
    }
}