export class DeliveryEstimateApiModel {
    organisationID: number;
    postcode: string;
    caseQuantity: number;
    orderDate: Date;
    deliveryMessage: string;
    deliveryCostGross: number;
    deliveryCostNet: number;
    deliveryVAT: number;
    deliveryProvider: string;
    deliveryNotes: string;
}