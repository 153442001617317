import * as React from "react";
import { RioResources, RioUnsafe, RioMenuItemApiModel } from "rio.react.all";


interface ISideMenuItemProps {
    item: RioMenuItemApiModel;
    active: Boolean;
    menuItem: string;
    itemClicked: (item: RioMenuItemApiModel) => void;
    itemMiddleClicked?: (item: RioMenuItemApiModel) => void;
}

export class RioSideMenuItem extends React.Component<ISideMenuItemProps> {
    render() {
        let resource = RioResources.find(this.props.item.resourceName);
        if (!resource) return null;

        return (
            <div className={"sideMenuMainItem" + (this.props.active ? " active" : "")} data-menu={this.props.menuItem} onClick={() => this.props.itemClicked(this.props.item)}>
                <div className="sideMenuImageBorder"><div className="sideMenuImageHolder"><RioUnsafe html={resource.image} /></div></div>
                <span className="text">{resource.text}</span>
            </div>
        );
    }
}