import moment from 'moment';
import * as React from 'react';
import { IGridViewAction, IGridViewColumn, RioConfirmModal, RioGridView, RioResources, RioIfFunc } from "rio.react.all";
import { UserApi } from "../apiLayer/UserApi";
import { UserApiModel } from "../apiModels/user/userApiModel";
import { UserAddUpdateModal } from "../modals/UserAddUpdate";


interface UsersProps {
}

interface UsersState {
    // UI State
    loading: boolean;
    selectedUserId?: number;
    deleteUserId?: number;
    users: UserApiModel[];
}

export class Users extends React.Component<UsersProps, UsersState> {
    constructor(props: UsersProps) {
        super(props);

        this.state = {
            loading: false,
            selectedUserId: null,
            deleteUserId: null,
            users: []
        };
    }

    columns: Array<IGridViewColumn<UserApiModel>> = [
        { title: RioResources.find("FirstName").text, value: (item) => item.firstName },
        { title: RioResources.find("LastName").text, value: (item) => item.lastName },
        { title: RioResources.find("Email").text, value: (item) => item.email },
        {
            title: RioResources.find("LastLogin").text,
            value: (item) => item.lastLogin,
            displayValue: (item) => !!item.lastLogin ? moment(item.lastLogin).format("LLL") : "Never"
        },
    ];

    actions: Array<IGridViewAction<UserApiModel>> = [
        { resource: RioResources.find("Delete"), onClick: (item) => this.handleDeleteClicked(item.userID), default: false, hasPermission: true },
        { resource: RioResources.find("Edit"), onClick: (item) => this.handleEditClicked(item.userID), default: true, hasPermission: true },
    ];

    componentDidMount() {
        this.getUsers();
    }

    getUsers() {
        UserApi.getUsers(response => {
            if (response.success) {
                this.setState({
                    users: response.item,
                    loading: false
                });
            } else {
                this.setState({
                    users: [],
                    loading: false
                });
            }
        });
    }

    render() {
        return (
            <div>
                <div className="screenTitle">
                    <h1>{RioResources.find("Users").text}</h1>
                </div>

                <button className="newUserButton" onClick={() => this.addUser()}>
                    {RioResources.find("NewUser").text}
                </button>

                <RioGridView<UserApiModel>
                    noResults={RioResources.find("NoResultsFound")}
                    columns={this.columns}
                    actions={this.actions}
                    items={this.state.users}
                    rowKey={(item: UserApiModel) => item.userID}
                />

                <RioIfFunc condition={this.state.selectedUserId !== null} then={() => {
                    return (
                        <UserAddUpdateModal 
                            userId={this.state.selectedUserId} 
                            onClose={() => this.handleUserAddUpdateModalClosed()}
                        />
                    );
                }} />

                <RioIfFunc condition={!!this.state.deleteUserId} then={() => {
                    return (
                        <RioConfirmModal 
                            text={RioResources.find("ConfirmDelete")} 
                            onConfirmModalClosed={(result) => this.handleConfirmDeleteModalClosed(result)} 
                            hideImage={true}
                        />
                    );
                }} />
            </div>
        );
    }

    addUser(): void {
        this.setState({
            selectedUserId: 0
        });
    }

    handleEditClicked(userID: number): void {
        this.setState({
            selectedUserId: userID
        });
    }

    handleUserAddUpdateModalClosed() {
        this.setState({
            selectedUserId: null
        }, () => {
            this.getUsers();
        });
    }

    handleDeleteClicked(userID: number): void {
        this.setState({
            deleteUserId: userID
        });
    }

    handleConfirmDeleteModalClosed(result: boolean) {
        if (result) {
            UserApi.deleteUser(this.state.deleteUserId, response => {
                if (response.success) {
                    this.setState({
                        deleteUserId: null
                    }, () => {
                        this.getUsers();
                    });
                }
            });
        } else {
            this.setState({
                deleteUserId: null
            });
        }
    }
}