import * as React from "react";
import { RioExpandingPanel, RioIconButton, RioModal, RioResources } from "rio.react.all";

interface IErrorModalProps {
    //text: IResource;
    text: string;
    onErrorModalClosed: () => void;
}

export class ErrorModal extends React.Component<IErrorModalProps> {
    render() {
        return (
            <RioModal className="ErrorModal" visible={true} onCancel={() => this.props.onErrorModalClosed()}>
                <RioExpandingPanel expandedDefault={true} disableToggle={true} resource={RioResources.find("Error")}>
                    <div className="ErrorModalText">{this.props.text}</div>
                </RioExpandingPanel>
                <div className="buttons">
                    <RioIconButton hasPermission={true} resource={RioResources.find("Confirm")} onClick={() => this.props.onErrorModalClosed()} />
                </div>
            </RioModal>
        );
    }
}
