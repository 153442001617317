import moment from "moment";
import { RioDateHelpers } from "rio.react.all";

export class CustomerCategoryRepairApiModel {
    constructor() {
        this.dateFrom =  RioDateHelpers.parseOutputDate(moment().subtract(7, 'days').toDate());
        this.brandId = 0;
    }

    dateFrom: string;
    brandId: number;
}