import { ApiResponse } from "rio.react.all";
import { RioResources } from "rio.react.all";
import { RioValidator } from "rio.react.all";

export class HttpErrorHelper {
    static handleHttpError(response: ApiResponse<any>, component: React.Component, validator: RioValidator = null): string {
        if (!!response.errors && !!validator) {
            validator.addModelErrors(component, response.errors);
            return null;
        } else {
            if (!!response.item) {
                return response.item;
            } else {
                return RioResources.find("HttpResponseErrorMsg").text.replace("{statusCode}", response.statusCode ? response.statusCode.toString() : "");
            }

        }
    }
}