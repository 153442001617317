import * as React from 'react';
import { Spinner } from 'reactstrap';
import { RioApiPost, RioIconButton, RioIf, RioInput, RioResources, RioValidator } from "rio.react.all";
import { BaseUserLoginViewModel } from "../apiModels/user/baseUserLoginViewModel";
import '../styles/login.css';

interface LoginProps {
}


interface LoginState {
    model: BaseUserLoginViewModel;
    loading: boolean;
    error: boolean;
    resetPassword: boolean;
    resetPasswordSent: boolean;
}

export class Login extends React.Component<LoginProps, LoginState> {

    constructor(props: LoginProps) {
        super(props);
        this.state = {
            model: new BaseUserLoginViewModel(),
            loading: false,
            error: false,
            resetPassword: false,
            resetPasswordSent: false
        };
    }

    private validator = new RioValidator();

    componentDidMount() {

    }

    render() {
        return (
            <div className="rioLogin">
                <div className="rightPanel">
                    <RioIf condition={this.state.resetPasswordSent} then={
                        <div className="loginform">
                            <p>{RioResources.find("PasswordResetEmailSent").text}</p>

                            <p className="link" onClick={() => this.cancelReset()}>
                                {RioResources.find("LoginLink").text}
                            </p>
                        </div>
                    } otherwise={
                        <div className="loginform">
                            <RioIf condition={this.state.loading} then={
                                <Spinner />
                            } />

                            <RioIf condition={!this.state.loading} then={
                                <RioInput 
                                    name="Email" 
                                    type="email"
                                    value={this.state.model.email ?? ""}
                                    onChange={(e) => this.handleEmailChanged(e)}
                                    validator={this.validator}
                                    validations={{ required: true }}
                                    placeholder={RioResources.find("Email").text}
                                />
                            } />

                            <RioIf condition={!this.state.resetPassword && !this.state.loading} then={
                                <RioInput 
                                    name="Password" 
                                    type="password"
                                    value={this.state.model.password ?? ""}
                                    onChange={(e) => this.handlePasswordChanged(e)}
                                    validator={this.validator}
                                    validations={{ required: true }}
                                    placeholder={RioResources.find("Password").text}
                                    onKeyDown={(e) => this.handlePasswordKeyDown(e)}
                                />
                            } />

                            <RioIf condition={!this.state.loading} then={
                                <RioIconButton 
                                    hideImage={true}
                                    resource={this.state.resetPassword ? RioResources.find("PasswordResetButton") : RioResources.find("Login")}
                                    onClick={() => this.submit()}
                                    hasPermission={true}>
                                </RioIconButton>
                            } />

                            <RioIf condition={!this.state.loading && this.state.resetPassword} then={
                                <RioIconButton 
                                    hideImage={true}
                                    resource={RioResources.find("Cancel")}
                                    onClick={() => this.cancelReset()}
                                    hasPermission={true}>
                                </RioIconButton>
                            } />

                            <RioIf condition={!this.state.resetPassword && !this.state.loading} then={
                                <p className="link" onClick={() => this.clickedResetPasswordLink()}>
                                    {RioResources.find("PasswordResetLinkText").text}
                                </p>
                            } />
                        </div>
                    } />
                </div>
            </div>
        );
    }

    handleEmailChanged(event: React.ChangeEvent<HTMLInputElement>) {
        let model = {
            ...this.state.model,
            email: event.target.value
        };

        this.setState({ model: model });
    }

    handlePasswordChanged(event: React.ChangeEvent<HTMLInputElement>) {
        let model = {
            ...this.state.model,
            password: event.target.value
        };

        this.setState({ model: model });
    }

    submit() {
        this.setState({
            loading: true
        }, () => {
            var submit = new RioApiPost<BaseUserLoginViewModel>(this.state.resetPassword ? "/api/userlogin/recovery" : "/api/userlogin/login", this.state.model);
            submit.onDone = (response) => { this.submitOnDone(response); };
            submit.send();
        });
    }

    cancelReset() {
        this.validator.clearModelErrors(this);

        this.setState({
            model: new BaseUserLoginViewModel(),
            resetPassword: false,
            resetPasswordSent: false
        });
    }

    submitOnDone(response: any) {
        if (response.success) {
            if (this.state.resetPassword) {
                this.setState({
                    resetPasswordSent: true,
                    loading: false
                });
            } else {
                window.location.href = "/";
            }
        } else {
            this.validator.clearModelErrors(this);

            this.setState({
                error: true,
                loading: false
            }, () => {
                this.validator.addModelErrors(this, response.errors.errors ?? response.errors);
            });
        }
    }

    clickedResetPasswordLink() {
        this.validator.clearModelErrors(this);

        this.setState({
            model: new BaseUserLoginViewModel(),
            resetPassword: true
        });
    }

    handlePasswordKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key === 'Enter') {
            this.submit();
        }
    }
}