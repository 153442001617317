export class BaseUserLoginViewModel {
    email: string;
    password: string;
    rememberMe: boolean;
    returnUrl: string;

    constructor() {
        this.email = "";
        this.password = "";
        this.rememberMe = true;
    }
}