import * as React from "react";
import { RouteComponentProps } from "react-router";
import { Spinner } from 'reactstrap';
import { RioIf, RioInput, RioModal, RioResources, RioValidator, RioDatePicker } from "rio.react.all";
import { FinancialYearApi } from "../apiLayer/FinancialYearApi";
import { FinancialYearApiModel } from "../apiModels/FinancialYear/FinancialYearApiModel";

interface IFinancialYearAddUpdateModalParams {
    userId: string;
}

interface IFinancialYearAddUpdateModalProps extends Partial<RouteComponentProps<IFinancialYearAddUpdateModalParams>> {
    financialYearID: number;
    onClose: () => void;
}

interface IFinancialYearAddUpdateModalState {
    // Bindings
    financialYear: FinancialYearApiModel;
    loading: boolean;
    saving: boolean;
    error: boolean;
}

export class FinancialYearAddUpdateModal extends React.Component<IFinancialYearAddUpdateModalProps, IFinancialYearAddUpdateModalState> {
    constructor(props: Readonly<IFinancialYearAddUpdateModalProps>) {
        super(props);

        this.state = {
            financialYear: null,
            loading: true,
            saving: false,
            error: false,
        };
    }

    private validator = new RioValidator();

    componentDidMount() {
        this.getFinancialYear();
    }

    getFinancialYear() {
        if (this.props.financialYearID === 0 || isNaN(this.props.financialYearID)) {
            var newFinancialYear = new FinancialYearApiModel();
            newFinancialYear.financialYearID = 0;

            this.setState({
                financialYear: newFinancialYear,
                loading: false
            });
        } else {
            FinancialYearApi.get(this.props.financialYearID, response => {
                if (response.success) {
                    this.setState({
                        financialYear: response.item,
                        loading: false
                    });
                } else {
                    this.setState({
                        error: true,
                        loading: false
                    });
                }
            });
        }
    }

    render() {
        return (
            <RioModal visible={true} onCancel={() => this.handleModalCloseClicked()} className="large">
                <div className="financialYearDetailsArea">
                    {this.renderModalContents()}
                </div>
            </RioModal>
        );
    }

    renderModalContents() {
        if (!!this.state.loading) {
            return (
                <div className="spinner-container">
                    <Spinner />
                </div>
            );
        }

        return (
            <React.Fragment>
                <div className="screenTitle">
                    {this.renderTitle()}
                </div>

                <div className="form">
                    <div className="horizontalFlex">
                        <label className="mandatory">{RioResources.find("Name").text}</label>
                        <RioInput
                            name="Name"
                            type="text"
                            value={this.state.financialYear?.name ?? ""}
                            onChange={(e) => this.handleValueChanged("name", e.target.value)}
                            validator={this.validator}
                            validations={{ required: true }}
                        />
                    </div>

                    <div className="horizontalFlex">
                        <label className="mandatory">{RioResources.find("DateFrom").text}</label>
                        <RioDatePicker
                            name="DateFrom"
                            from={this.state.financialYear?.dateStart}
                            validator={this.validator}
                            time={false}
                            onChange={(date) => this.handleValueChanged("dateStart", date)}
                        />
                    </div>

                    <div className="horizontalFlex">
                        <label className="mandatory">{RioResources.find("DateTo").text}</label>
                        <RioDatePicker
                            name="DateTo"
                            from={this.state.financialYear?.dateEnd}
                            validator={this.validator}
                            time={false}
                            onChange={(date) => this.handleValueChanged("dateEnd", date)}
                        />
                    </div>

                    <div className="horizontalFlex">
                        <label>{RioResources.find("DateClosed").text}</label>
                        <RioDatePicker
                            name="DateClosed"
                            from={this.state.financialYear?.dateClosed}
                            validator={this.validator}
                            nullable={true}
                            utc={true}
                            time={true}
                            onChange={(date) => this.handleValueChanged("dateClosed", date)}
                        />
                    </div>
                </div>

                <div className="horizontalFlex formButtons">
                    <div className="buttonHolder">
                        <button onClick={() => this.cancel()}>
                            {RioResources.find("Cancel").text}
                        </button>
                    </div>

                    <div className="buttonHolder">
                        <RioIf condition={this.state.saving} then={
                            <Spinner />
                        } otherwise={
                            <button onClick={() => this.save()}>
                                {RioResources.find("Save").text}
                            </button>
                        } />
                    </div>
                </div>
            </React.Fragment>
        );
    }

    renderTitle() {
        if (!this.state.financialYear) {
            return "";
        }

        if (this.state.financialYear.financialYearID === 0) {
            return <h1>{RioResources.find("NewFinancialYear").text}</h1>;
        }

        return <h1>{RioResources.find("EditFinancialYear").text}</h1>;
    }

    cancel() {
        this.props.onClose();
    }

    save() {
        this.setState({
            saving: true
        }, () => {
            FinancialYearApi.addUpdate(this.state.financialYear, response => {
                if (response.success) {
                    this.props.onClose();
                } else {
                    this.validator.clearModelErrors(this);
                    this.validator.addModelErrors(this, response.errors);

                    this.setState({
                        error: true,
                        saving: false
                    });
                }
            });
        });
    }

    handleModalCloseClicked() {
        this.props.onClose();
    }

    handleValueChanged(key: string, value: string) {
        const financialYear: FinancialYearApiModel = {
            ...this.state.financialYear,
            [key]: value
        };

        this.setState({ financialYear: financialYear });
    }
}
