import { RioDateHelpers } from 'rio.react.all';

export class GrossMarginReportCriteriaApiModel {
    salesChannelIDs: string[];
    dateFrom: string;
    dateTo: string;

    constructor() {
        this.salesChannelIDs = [];

        const today = new Date();
        const year = today.getFullYear() - (today.getMonth() >= 6 ? 0 : 1);

        // Set default DateFrom to previous July 1st
        this.dateFrom = RioDateHelpers.parseOutputDate(new Date(year, 6, 1, 0, 0, 0));

        // Set default DateTo to today
        this.dateTo = RioDateHelpers.parseOutputDate(new Date());
    }
}