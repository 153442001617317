import { ApiResponse } from "rio.react.all";
import { FinancialYearApiModel } from "../apiModels/FinancialYear/FinancialYearApiModel";
import { BaseApi } from "./BaseApi";

export class FinancialYearApi {
    static getList(ondone: (response: ApiResponse<FinancialYearApiModel[]>) => any) {
        BaseApi.get("/api/financialyear", ondone);
    }

    static get(financialYearID: number, ondone: (response: ApiResponse<FinancialYearApiModel>) => any) {
        BaseApi.get("/api/financialyear/" + financialYearID, ondone);
    }

    static addUpdate(financialYear: FinancialYearApiModel, ondone: (response: ApiResponse<object>) => any) {
        BaseApi.post("/api/financialyear", financialYear, ondone);
    }

    static delete(financialYearID: number, ondone: (response: ApiResponse<FinancialYearApiModel>) => any) {
        BaseApi.delete("/api/financialyear/" + financialYearID, ondone);
    }
}