export class CustomerSearchApiModel {
    constructor() {
        this.accountNo = "";
        this.firstName = "";
        this.lastName = "";
        this.brandId = 1;
        this.categoryIDs = [];
    }
    accountNo: string;
    firstName: string;
    lastName: string;
    brandId: number;
    categoryIDs: number[];
}