export class ProductCodePrefixSuffixApiModel {
    prefix: string;
    drinkType: string;
    drinkColour: string;
    countryTerritory: string;
    region: string;
    notes: string;
    suffix: string;
    unitOfMeasure: string;
    quantity: number;
    vintage: string;
}