import * as React from 'react';
import { IGridViewAction, IGridViewColumn, RioConfirmModal, RioGridView, RioIfFunc, RioResources, RioDateHelpers } from "rio.react.all";
import { FinancialYearApi } from "../apiLayer/FinancialYearApi";
import { FinancialYearApiModel } from "../apiModels/FinancialYear/FinancialYearApiModel";
import { FinancialYearAddUpdateModal } from "../modals/FinancialYearAddUpdateModal";


interface FinancialYearsProps {
}

interface FinancialYearsState {
    // UI State
    loading: boolean;
    selectedFinancialYearID?: number;
    deleteFinancialYearID?: number;
    financialYears: FinancialYearApiModel[];
}

export class FinancialYears extends React.Component<FinancialYearsProps, FinancialYearsState> {
    constructor(props: FinancialYearsProps) {
        super(props);

        this.state = {
            loading: false,
            selectedFinancialYearID: null,
            deleteFinancialYearID: null,
            financialYears: []
        };
    }

    columns: Array<IGridViewColumn<FinancialYearApiModel>> = [
        { title: RioResources.find("Name").text, value: (item) => item.name },
        { title: RioResources.find("DateFrom").text, value: (item) => RioDateHelpers.parseDisplayDate(item.dateStart, "dd/MM/yyyy") },
        { title: RioResources.find("DateTo").text, value: (item) => RioDateHelpers.parseDisplayDate(item.dateEnd, "dd/MM/yyyy") },
        { title: RioResources.find("DateClosed").text, value: (item) => RioDateHelpers.parseDisplayDate(item.dateClosed, "dd/MM/yyyy HH:mm:ss", true) }
    ];

    actions: Array<IGridViewAction<FinancialYearApiModel>> = [
        { resource: RioResources.find("Delete"), onClick: (item) => this.handleDeleteClicked(item.financialYearID), default: false, hasPermission: true },
        { resource: RioResources.find("Edit"), onClick: (item) => this.handleEditClicked(item.financialYearID), default: true, hasPermission: true },
    ];

    componentDidMount() {
        this.getFinancialYears();
    }

    getFinancialYears() {
        FinancialYearApi.getList(response => {
            this.setState({
                financialYears: response.success ? response.item : [],
                loading: false
            });
        });
    }

    render() {
        return (
            <div>
                <div className="screenTitle">
                    <h1>{RioResources.find("FinancialYears").text}</h1>
                </div>

                <button className="newFinancialYearButton" onClick={() => this.addFinancialYear()}>
                    {RioResources.find("NewFinancialYear").text}
                </button>

                <RioGridView<FinancialYearApiModel>
                    noResults={RioResources.find("NoResultsFound")}
                    columns={this.columns}
                    actions={this.actions}
                    items={this.state.financialYears}
                    rowKey={(item: FinancialYearApiModel) => item.financialYearID}
                />

                <RioIfFunc condition={this.state.selectedFinancialYearID !== null} then={() => {
                    return (
                        <FinancialYearAddUpdateModal 
                            financialYearID={this.state.selectedFinancialYearID} 
                            onClose={() => this.handleFinancialYearAddUpdateModalClosed()}
                        />
                    );
                }} />

                <RioIfFunc condition={!!this.state.deleteFinancialYearID} then={() => {
                    return (
                        <RioConfirmModal 
                            text={RioResources.find("ConfirmDelete")} 
                            onConfirmModalClosed={(result) => this.handleConfirmDeleteModalClosed(result)} 
                            hideImage={true}
                        />
                    );
                }} />
            </div>
        );
    }

    addFinancialYear(): void {
        this.setState({
            selectedFinancialYearID: 0
        });
    }

    handleEditClicked(financialYearID: number): void {
        this.setState({
            selectedFinancialYearID: financialYearID
        });
    }

    handleFinancialYearAddUpdateModalClosed() {
        this.setState({
            selectedFinancialYearID: null
        }, () => {
            this.getFinancialYears();
        });
    }

    handleDeleteClicked(FinancialYearID: number): void {
        this.setState({
            deleteFinancialYearID: FinancialYearID
        });
    }

    handleConfirmDeleteModalClosed(result: boolean) {
        if (result) {
            FinancialYearApi.delete(this.state.deleteFinancialYearID, response => {
                if (response.success) {
                    this.setState({
                        deleteFinancialYearID: null
                    }, () => {
                        this.getFinancialYears();
                    });
                }
            });
        } else {
            this.setState({
                deleteFinancialYearID: null
            });
        }
    }
}