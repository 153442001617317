import * as React from 'react';
import { IGridViewColumn, RioConfirmModal, RioGridView, RioIf, RioInput, RioResources, RioSelect, RioValidator } from "rio.react.all";
import { ProductCodeApi } from "../apiLayer/productCodeApi";
import { ProductCodePrefixApiModel } from "../apiModels/ProductCode/productCodePrefixApiModel";
import { ProductCodePrefixSuffixApiModel } from "../apiModels/ProductCode/productCodePrefixSuffixApiModel";
import { SelectOption } from "../helpers/RioSelectOption";


interface ProductCodeGeneratorProps {
}

interface ProductCodeGeneratorState {
    // UI State
    loading: boolean;
    prefixApiModel: ProductCodePrefixApiModel;
    prefixSuffixApiModel: ProductCodePrefixSuffixApiModel;
    drinkTypes: SelectOption[];
    selectedDrinkType: SelectOption;
    drinkColours: SelectOption[];
    selectedColour: SelectOption;
    countries: SelectOption[];
    selectedCountry: SelectOption;
    regions: SelectOption[];
    selectedRegion: SelectOption;
    suffixes: SelectOption[];
    selectedSuffix: SelectOption;
    prefixes: SelectOption[];
    productCodes: string[];
    confirmSaveProductCode: boolean;
    successfulProductSave: boolean;
    hideSaveButton: boolean;
    hideProductCodeGeneratorButton: boolean;
    error: boolean;
    productCodesAlreadyExists: boolean;
}

export class ProductCodeGenerator extends React.Component<ProductCodeGeneratorProps, ProductCodeGeneratorState> {
    constructor(props: ProductCodeGeneratorState) {
        super(props);

        this.state = {
            loading: false,
            prefixApiModel: new ProductCodePrefixApiModel(),
            prefixSuffixApiModel: new ProductCodePrefixSuffixApiModel(),
            drinkTypes: [],
            selectedDrinkType: null,
            drinkColours: [],
            selectedColour: null,
            countries: [],
            selectedCountry: null,
            regions: [],
            selectedRegion: null,
            suffixes: [],
            selectedSuffix: null,
            prefixes: [],
            productCodes: [],
            confirmSaveProductCode: false,
            successfulProductSave: false,
            hideSaveButton: true,
            hideProductCodeGeneratorButton: true,
            error: false,
            productCodesAlreadyExists: false
        };

        this.showHideGenerateButton = this.showHideGenerateButton.bind(this);
    }

    private validator = new RioValidator();

    columns: Array<IGridViewColumn<string>> = [
        { title: RioResources.find("ProductCode").text, value: (item) => item }
    ];

    componentDidMount() {
        this.getDrinkTypes();

        ProductCodeApi.getSuffix(response => {
            const prefixSuffixApiModel: ProductCodePrefixSuffixApiModel = {
                ...this.state.prefixSuffixApiModel,
                suffix: response.item[0].value
            };

            this.setState({
                suffixes: response.item,
                prefixSuffixApiModel: prefixSuffixApiModel
            });
        });
    }

    render() {
        return (
            <div id="productCodeGenerator" className="productSearch">
                <div className="screenTitle">
                    <h1>{RioResources.find("ProductCodeGenerator").text}</h1>
                </div>

                <div className="searchArea">
                    <div className="searchField">
                        <label className="mandatory">{RioResources.find("DrinkType").text}</label>
                        <RioSelect<SelectOption>
                            name="DrinkType"
                            items={this.state.drinkTypes}
                            value={!!this.state.selectedDrinkType ? [this.state.selectedDrinkType] : []}
                            keySelector={(item: SelectOption) => { return item.value }}
                            descriptionSelector={(item: SelectOption) => { return item.text }}
                            onSuggestionSelected={(value: SelectOption) => { this.handleDrinkTypeChanged(value, this.showHideGenerateButton) }}
                        />
                    </div>

                    <RioIf condition={this.state.drinkColours.length > 1} then={
                        <div className="searchField">
                            <label className={!this.state.drinkColours.some(r => r.value === "") ? "mandatory" : ""}>{RioResources.find("DrinkColour").text}</label>
                            <RioSelect<SelectOption>
                                name="Colour"
                                items={this.state.drinkColours}
                                value={!!this.state.selectedColour ? [this.state.selectedColour] : []}
                                keySelector={(item: SelectOption) => { return item.value }}
                                descriptionSelector={(item: SelectOption) => { return item.text }}
                                onSuggestionSelected={(value: SelectOption) => { this.handleDrinkColourChanged(value, this.showHideGenerateButton) }}
                            />
                        </div>
                    } />

                    <RioIf condition={this.state.drinkColours.length > 1 && this.state.countries.length >= 1} then={
                        <div className="searchField">
                            <label className="mandatory">{RioResources.find("CountryTerritory").text}</label>
                            <RioSelect<SelectOption>
                                name="Country"
                                items={this.state.countries}
                                value={!!this.state.selectedCountry ? [this.state.selectedCountry] : []}
                                keySelector={(item: SelectOption) => { return item.value }}
                                descriptionSelector={(item: SelectOption) => { return item.text }}
                                onSuggestionSelected={(value: SelectOption) => { this.handleCountryChanged(value, this.showHideGenerateButton) }}
                            />
                        </div>
                    } />

                    <RioIf condition={this.state.regions.length > 1} then={
                        <div className="searchField">
                            <label className={!this.state.regions.some(r => r.value === "") ? "mandatory" : ""}>{RioResources.find("Region").text}</label>
                            <RioSelect<SelectOption>
                                name="Region"
                                items={this.state.regions}
                                value={!!this.state.selectedRegion ? [this.state.selectedRegion] : []}
                                keySelector={(item: SelectOption) => item.value}
                                descriptionSelector={(item: SelectOption) => item.text}
                                onSuggestionSelected={(value: SelectOption) => this.handleRegionChanged(value, this.showHideGenerateButton)}
                            />
                        </div>
                    } />
                </div>

                <div className="searchArea">
                    <div className="searchField">
                        <label className="mandatory">{RioResources.find("Suffix").text}</label>
                        <RioSelect<SelectOption>
                            name="Suffix"
                            items={this.state.suffixes}
                            value={!!this.state.selectedSuffix ? [this.state.selectedSuffix] : []}
                            keySelector={(item: SelectOption) => item.value}
                            descriptionSelector={(item: SelectOption) => item.text}
                            onSuggestionSelected={(value: SelectOption) => this.handleSuffixChanged(value, this.showHideGenerateButton)}
                        />
                    </div>

                    <div className="searchField">
                        <label className="mandatory">{RioResources.find("Vintage").text}</label>
                        <RioInput
                            name="Vintage"
                            type="number"
                            step={2}
                            required={true}
                            value={this.state.prefixSuffixApiModel.vintage}
                            onChange={(e) => this.handleVintageChanged(e, this.showHideGenerateButton)}
                            validator={this.validator}
                            validations={{ required: true }}
                        />
                    </div>

                    <div className="searchField">
                        <label className="mandatory">{RioResources.find("Quantity").text}</label>
                        <RioInput
                            name="Quantity"
                            type="number"
                            step={2}
                            required={true}
                            value={this.state.prefixSuffixApiModel.quantity}
                            onChange={(e) => this.handleQuantityChanged(e, this.showHideGenerateButton)}
                            validator={this.validator}
                            validations={{ required: true }}
                        />
                    </div>
                </div>

                <RioIf condition={!this.state.hideProductCodeGeneratorButton} then={
                    <div className="horizontalFlexRight">
                        <button className="button" onClick={() => this.generateProductCode()}>
                            {RioResources.find("GenerateProductCode").text}
                        </button>
                    </div>
                } />

                <RioIf condition={this.state.productCodes.length > 0} then={
                    <RioGridView<string>
                        noResults={RioResources.find("NoResultsFound")}
                        columns={this.columns}
                        items={this.state.productCodes}
                        rowKey={(item: string) => item}
                    />
                } />

                <RioIf condition={this.state.productCodes.length > 0 && !this.state.hideSaveButton} then={
                    <div className="horizontalFlexRight">
                        <button type="submit" className="button" onClick={() => this.setState({ confirmSaveProductCode: true })}>
                            {RioResources.find("SaveProductCode").text}
                        </button>
                    </div>
                } />

                <RioIf condition={this.state.successfulProductSave} then={
                    <div className="horizontalFlexCenter">
                        <label>{RioResources.find("SavedProductCodesMessage").text}</label>
                    </div>
                } />

                <RioIf condition={!this.state.successfulProductSave && this.state.productCodesAlreadyExists} then={
                    <div className="horizontalFlexCenter">
                        <label>{RioResources.find("ProductCodesAlreadyExistsMessage").text}</label>
                    </div>
                } />

                <RioIf condition={this.state.confirmSaveProductCode} then={
                    <RioConfirmModal
                        hideImage={true}
                        text={RioResources.find("AreYouSureMessage")}
                        onConfirmModalClosed={(result) => this.saveProductCode(result)}
                    />
                } />
            </div>
        );
    }

    getDrinkTypes(callback: () => void = () => {}) {
        ProductCodeApi.getDrinkTypes(response => {
            this.setState({
                drinkTypes: response.item ?? [],
                productCodes: []
            }, () => {
                if (response.item[0]?.value === "") {
                    this.handleDrinkTypeChanged(response.item[0], callback);
                } else {
                    this.setState({
                        drinkColours: [],
                        countries: [],
                        regions: [],
                        selectedColour: null,
                        selectedCountry: null,
                        selectedRegion: null
                    }, callback);
                }
            });
        });
    }

    getDrinkColours(prefixApiModel: ProductCodePrefixApiModel, prefixSuffixApiModel: ProductCodePrefixSuffixApiModel, callback: () => void = () => {}) {
        ProductCodeApi.getDrinkColour(prefixApiModel, response => {
            this.setState({
                drinkColours: response.item ?? [],
                productCodes: []
            }, () => {
                if (response.item?.length === 1 || response.item[0]?.value === "") {
                    this.handleDrinkColourChanged(response.item[0], callback);
                } else {
                    this.setState({
                        countries: [],
                        regions: [],
                        selectedCountry: null,
                        selectedRegion: null
                    }, callback);
                }
            });
        });
    }

    getCountryTerritories(prefixApiModel: ProductCodePrefixApiModel, prefixSuffixApiModel: ProductCodePrefixSuffixApiModel, callback: () => void = () => {}) {
        ProductCodeApi.getCountryTerritory(prefixApiModel, response => {
            this.setState({
                countries: response.item ?? [],
                productCodes: []
            }, () => {
                if (response.item?.length === 1) {
                    this.handleCountryChanged(response.item[0], callback);
                } else {
                    this.setState({
                        regions: [],
                        selectedRegion: null
                    }, callback);
                }
            });
        });
    }

    getRegions(prefixApiModel: ProductCodePrefixApiModel, prefixSuffixApiModel: ProductCodePrefixSuffixApiModel, callback: () => void = () => {}) {
        ProductCodeApi.getRegion(prefixApiModel, response => {
            this.setState({
                regions: response.item ?? [],
                productCodes: []
            }, () => {
                if (response.item?.length === 1 || response.item[0]?.value === "") {
                    this.handleRegionChanged(response.item[0], callback);
                } else {
                    callback();
                }
            });
        });
    }

    handleDrinkTypeChanged(selectedOption: SelectOption, callback: () => void = () => {}) {
        const prefixApiModel: ProductCodePrefixApiModel = {
            ...this.state.prefixApiModel,
            drinkType: selectedOption.value
        };

        const prefixSuffixApiModel: ProductCodePrefixSuffixApiModel = {
            ...this.state.prefixSuffixApiModel,
            drinkType: selectedOption.value,
            drinkColour: null,
            countryTerritory: null,
            region: null
        };

        this.setState({
            prefixApiModel: prefixApiModel,
            prefixSuffixApiModel: prefixSuffixApiModel,
            productCodes: [],
            successfulProductSave: false,
            selectedDrinkType: selectedOption,
            selectedRegion: null,
            regions: [],
            selectedColour: null,
            drinkColours: [],
            selectedCountry: null,
            countries: []
        }, () => {
            this.getDrinkColours(prefixApiModel, prefixSuffixApiModel, callback);
        });
    }

    handleDrinkColourChanged(selectedOption: SelectOption, callback: () => void = () => {}) {
        const prefixApiModel: ProductCodePrefixApiModel = {
            ...this.state.prefixApiModel,
            drinkColour: selectedOption.value,
            countryTerritory: null,
            region: null
        };

        const prefixSuffixApiModel: ProductCodePrefixSuffixApiModel = {
            ...this.state.prefixSuffixApiModel,
            drinkColour: selectedOption.value,
            countryTerritory: null,
            region: null
        };

        this.setState({
            prefixApiModel: prefixApiModel,
            prefixSuffixApiModel: prefixSuffixApiModel,
            productCodes: [],
            successfulProductSave: false,
            selectedColour: selectedOption,
            selectedCountry: null,
            countries: [],
            selectedRegion: null,
            regions: []
        }, () => {
            this.getCountryTerritories(prefixApiModel, prefixSuffixApiModel, callback);
        });
    }

    handleCountryChanged(selectedOption: SelectOption, callback: () => void = () => {}) {
        const prefixApiModel: ProductCodePrefixApiModel = {
            ...this.state.prefixApiModel,
            countryTerritory: selectedOption.value,
            region: null
        };

        const prefixSuffixApiModel: ProductCodePrefixSuffixApiModel = {
            ...this.state.prefixSuffixApiModel,
            countryTerritory: selectedOption.value,
            region: null
        };

        this.setState({
            prefixApiModel: prefixApiModel,
            prefixSuffixApiModel: prefixSuffixApiModel,
            productCodes: [],
            successfulProductSave: false,
            selectedCountry: selectedOption,
            selectedRegion: null,
            regions: []
        }, () => {
            this.getRegions(prefixApiModel, prefixSuffixApiModel, callback);
        });
    }

    handleRegionChanged(selectedOption: SelectOption, callback: () => void = () => {}) {
        const prefixApiModel: ProductCodePrefixApiModel = {
            ...this.state.prefixApiModel,
            region: selectedOption.value
        };

        const prefixSuffixApiModel: ProductCodePrefixSuffixApiModel = {
            ...this.state.prefixSuffixApiModel,
            region: selectedOption.value
        };

        this.setState({
            prefixApiModel: prefixApiModel,
            prefixSuffixApiModel: prefixSuffixApiModel,
            productCodes: [],
            successfulProductSave: false,
            selectedRegion: selectedOption
        }, callback);
    }

    handleSuffixChanged(selectedOption: SelectOption, callback: () => void = () => {}) {
        const prefixSuffixApiModel: ProductCodePrefixSuffixApiModel = {
            ...this.state.prefixSuffixApiModel,
            suffix: selectedOption.value
        };

        this.setState({
            prefixSuffixApiModel: prefixSuffixApiModel,
            successfulProductSave: false,
            selectedSuffix: selectedOption
        }, callback);
    }

    handleQuantityChanged(event: React.ChangeEvent<HTMLInputElement>, callback: () => void = () => {}) {
        const prefixSuffixApiModel: ProductCodePrefixSuffixApiModel = {
            ...this.state.prefixSuffixApiModel,
            quantity: parseInt(event.target.value)
        };

        this.setState({
            prefixSuffixApiModel: prefixSuffixApiModel,
            successfulProductSave: false
        }, callback);
    }

    handleVintageChanged(event: React.ChangeEvent<HTMLInputElement>, callback: () => void = () => {}) {
        const prefixSuffixApiModel: ProductCodePrefixSuffixApiModel = {
            ...this.state.prefixSuffixApiModel,
            vintage: event.target.value
        };

        this.setState({
            prefixSuffixApiModel: prefixSuffixApiModel,
            successfulProductSave: false
        }, callback);
    }
    
    generateProductCode() {
        this.setState({
            productCodes: [],
            hideSaveButton: true,
            successfulProductSave: false,
            error: false
        }, () => {
            ProductCodeApi.generateProductCode(this.state.prefixSuffixApiModel, response => {
                if (response.success) {
                    this.setState({
                        productCodes: response.item,
                        successfulProductSave: false,
                        hideSaveButton: false,
                        error: false
                    });
                } else {
                    this.validator.clearModelErrors(this);
                    this.validator.addModelErrors(this, response.errors);

                    this.setState({
                        error: true
                    });
                }
            });
        })
    }

    saveProductCode(result: boolean) {
        if (result) {
            ProductCodeApi.saveProductCodes(this.state.productCodes, response => {
                if (response.success && response.item > 0) {
                    this.setState({
                        hideProductCodeGeneratorButton: true,
                        successfulProductSave: true,
                        hideSaveButton: true
                    });
                } else {
                    this.setState({
                        successfulProductSave: false
                    });
                }
            });
        }

        this.setState({
            confirmSaveProductCode: false
        });
    }

    showHideGenerateButton() {
        this.setState({
            hideProductCodeGeneratorButton: !this.determineGenerateButtonEnabled()
        });
    }

    determineGenerateButtonEnabled(): boolean {
        const prefixSuffixApiModel = this.state.prefixSuffixApiModel;

        const enabled = !!prefixSuffixApiModel
            && !!prefixSuffixApiModel.drinkType
            && (!this.state.drinkColours.length || this.state.drinkColours.some(r => r.value === "") || !!prefixSuffixApiModel.drinkColour)
            && (!this.state.countries.length || this.state.countries.some(r => r.value === "") || !!prefixSuffixApiModel.countryTerritory)
            && (!this.state.regions.length || this.state.regions.some(r => r.value === "") || !!prefixSuffixApiModel.region)
            && !!prefixSuffixApiModel.suffix
            && !!prefixSuffixApiModel.vintage
            && !!prefixSuffixApiModel.quantity;

        return enabled;
    }
}