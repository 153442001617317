import * as Chart from 'chart.js';
import * as React from 'react';
import { Line } from 'react-chartjs-2';
import { RouteComponentProps } from "react-router";
import { Spinner } from "reactstrap";
import { RioApiPost, RioResources, RioValidator, RioDatePicker } from "rio.react.all";
import { ChartDataApiModel } from "../apiModels/chart/chartDataApiModel";
import { DistributionGraphFilterApiModel } from "../apiModels/customercategorydistribution/distributionGraphFilterApiModel";

interface CustomerCategoryDistributionProps extends Partial<RouteComponentProps> {
}

interface CustomerCategoryDistributionState {
    filterParams: DistributionGraphFilterApiModel;

    // UI State
    loading: boolean;
    //error: boolean;
    distributionGraphData: ChartDataApiModel;
}

export class CustomerCategoryDistribution extends React.Component<CustomerCategoryDistributionProps, CustomerCategoryDistributionState> {
    constructor(props: CustomerCategoryDistributionProps) {
        super(props);

        this.state = {
            loading: true,
            //error: false,
            filterParams: new DistributionGraphFilterApiModel(),
            distributionGraphData: null
        };

        Chart.defaults.global.elements.line.tension = 0.01;
    }

    private validator = new RioValidator();
    private dateFromDatePicker = React.createRef<RioDatePicker>();

    private chartColors: { [key: string]: string } = {
        "New": "#ED7D31",
        "Active (New)": "#FFEA00",
        "Active": "#397D02",
        "Lapsing": "#808080",
        "Lapsed": "#4472C4",
        "Bin": "#FF335E",
    };

    componentDidMount() {
        this.getGraph();
    }

    getGraph() {
        const call = new RioApiPost<null>("/api/customercategorydistribution/distributiongraph", this.state.filterParams);
        call.onDone = response => {
            if (response.success) {
                this.setState({
                    distributionGraphData: response.item
                });
            } else {
                //if (response.statusCode == 404) {
                //    this.setState({
                //        validationError: RioResources.find("CustomerReservePDFNotFound").text
                //    });
                //}
            }
        }
        call.send();
    }

    render() {
        return (
            <div className="customerCategoryDistributionPage">
                <div className="screenTitle">
                    <h1>{RioResources.find("CustomerCategoryDistributionGraph").text}</h1>
                </div>

                <div className="searchArea">
                    <div className="searchField">
                        <label>{RioResources.find("DateFrom").text}</label>
                        <RioDatePicker
                            ref={this.dateFromDatePicker}
                            name="fromDateInput"
                            validator={this.validator}
                            from={this.state.filterParams.dateFrom}
                            onChange={(date: string) => this.handleDateFromChanged(date)}
                            nullable={true}
                            time={false}
                            minDate={"2017-07-01"}
                        />
                    </div>
                </div>

                <div className="customerCategoryDistributionChart chart">
                    {this.renderCustomerCategoryDistributionChart()}
                </div>
            </div>
        );
    }

    renderCustomerCategoryDistributionChart() {
        if (!this.state.distributionGraphData) {
            return (<Spinner />);
        }

        if (!this.state.distributionGraphData.dataSets || !this.state.distributionGraphData.dataSets.length) {
            return (
                <div className="noData">
                    <p>{RioResources.find("NotEnoughData").text}</p>
                    <img src="/images/StrategyExample.png" alt="Example" />
                </div>
            );
        }

        var datasets = [];
        for (var i = 0; i < this.state.distributionGraphData.dataSets.length; i++) {
            if (this.state.distributionGraphData.dataSets[i].length) {
                datasets.push({
                    label: this.state.distributionGraphData.dataSetNames[i],
                    data: this.state.distributionGraphData.dataSets[i],
                    borderColor: this.chartColors[this.state.distributionGraphData.dataSetNames[i]],
                    pointBackgroundColor: "rgba(0,0,0,0)",
                    pointBorderColor: "rgba(0,0,0,0)",
                    fill: false,
                    hidden: this.state.distributionGraphData.dataSetNames[i] === "Bin"
                });
            }
        }

        const data = {
            labels: this.state.distributionGraphData.labels,
            datasets: datasets
        };

        const options = {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                xAxes: [{
                    ticks: {
                        autoSkip: true,
                        maxTicksLimit: 30
                    }
                }]
            }
        };

        return (
            <Line data={data} options={options} />
        );
    }

    handleDateFromChanged(date: string) {
        if (date !== null && this.state.filterParams.dateFrom !== date) {
            let filterParams = { 
                ...this.state.filterParams,
                dateFrom: date
            };

            this.setState({ 
                filterParams: filterParams 
            }, () => {
                this.getGraph();
            });
        }

        //this.setState({
        //    selectedCurrencyID: parseInt(selectedOption.value)
        //}, () => {
        //    this.getStockAndCharts();
        //});
    }
}