import { SecurityGroupApiModel } from "./userGroupApiModel";

export class UserApiModel {
    userID: number;
    firstName: string;
    lastName: string;
    email: string;
    lastLogin: string;
    //active: boolean;
    locked: boolean;
    securityGroups: SecurityGroupApiModel[];
    hasAccess: boolean;

    constructor() {
        this.securityGroups = [];
    }
}