import { ApiResponse } from "rio.react.all";
import { ProductCodePrefixApiModel } from "../apiModels/ProductCode/productCodePrefixApiModel";
import { ProductCodePrefixSuffixApiModel } from "../apiModels/ProductCode/productCodePrefixSuffixApiModel";
import { SelectOption } from "../helpers/RioSelectOption";
import { BaseApi } from "./BaseApi";

export class ProductCodeApi {
    static getDrinkTypes(ondone: (response: ApiResponse<SelectOption[]>) => any) {
        BaseApi.get("/api/productcode/getdrinktypes/", ondone);
    }

    static getDrinkColour(productCodePrefixApiModel: ProductCodePrefixApiModel, ondone: (response: ApiResponse<SelectOption[]>) => any) {
        BaseApi.post("/api/productcode/getdrinkcolour", productCodePrefixApiModel, ondone);
    }

    static getCountryTerritory(productCodePrefixApiModel: ProductCodePrefixApiModel, ondone: (response: ApiResponse<SelectOption[]>) => any) {
        BaseApi.post("/api/productcode/getcountryterritory", productCodePrefixApiModel, ondone);
    }

    static getRegion(productCodePrefixApiModel: ProductCodePrefixApiModel, ondone: (response: ApiResponse<SelectOption[]>) => any) {
        BaseApi.post("/api/productcode/getregion", productCodePrefixApiModel, ondone);
    }

    static generateProductCode(productCodePrefixSuffixApiModel: ProductCodePrefixSuffixApiModel, ondone: (response: ApiResponse<string[]>) => any) {
        BaseApi.post("/api/productcode/generateproductcode", productCodePrefixSuffixApiModel, ondone);
    }

    static saveProductCodes(productCodes: string[], ondone: (response: ApiResponse<number>) => any) {
        BaseApi.post("/api/productcode/saveproductcode", productCodes, ondone);
    }

    //static markStockForSale(internalStockIds: number[], ondone: (response: ApiResponse<void>) => any) {
    //    BaseApi.post("/api/stock/markforsale", internalStockIds, ondone);
    //}

    //static getProductPricingHistory(productPricingHistoryModel: ProductPricingHistoryApiModel,
    //    ondone: (response: ApiResponse<ProductApiModel[]>) => any) {
    //    BaseApi.post("/api/product/historicpricing", productPricingHistoryModel, ondone);
    //}

    static getSuffix(ondone: (response: ApiResponse<SelectOption[]>) => any) {
        BaseApi.get("/api/productcode/getsuffix", ondone);
    }
}