import moment from "moment";
import { RioDateHelpers } from "rio.react.all";

export class CustomerCategoryDistributionCacheRefreshApiModel {
    constructor() {
        this.dateFrom = RioDateHelpers.parseOutputDate(moment().subtract(7, 'days').toDate());
        this.dateTo = RioDateHelpers.parseOutputDate(new Date());
    }

    dateFrom: string;
    dateTo: string;
}