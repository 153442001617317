import { RioDateHelpers } from 'rio.react.all';

export class DistributionGraphFilterApiModel {
    dateFrom: string;

    constructor() {
        const today = new Date();
        const year = today.getFullYear() - (today.getMonth() <= 6 ? 4 : 3);

        this.dateFrom = RioDateHelpers.parseOutputDate(new Date(year, 6, 1, 0, 0, 0));
    }
}