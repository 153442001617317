import { UserApiModel } from "../apiModels/user/userApiModel";
import { ApiResponse } from "rio.react.all";
import { BaseApi } from "./BaseApi";
import { SecurityGroupApiModel } from "../apiModels/user/userGroupApiModel";


export class UserApi {

    static getUsers(ondone: (response: ApiResponse<UserApiModel[]>) => any) {
        BaseApi.get("/api/user", ondone);
    }

    static getUser(userId: number, ondone: (response: ApiResponse<UserApiModel>) => any) {
        BaseApi.get("/api/user/" + userId, ondone);
    }

    static postUser(user: UserApiModel, ondone: (response: ApiResponse<object>) => any) {
        BaseApi.post("/api/user", user, ondone);
    }

    static passwordReset(email: string, ondone: (response: ApiResponse<object>) => any) {
        BaseApi.post("/api/user/passwordreset/" + email, null, ondone);
    }

    static deleteUser(userId: number, ondone: (response: ApiResponse<UserApiModel>) => any) {
        BaseApi.delete("/api/user/" + userId, ondone);
    }

    static getAvailableGroups(ondone: (response: ApiResponse<SecurityGroupApiModel[]>) => any) {
        BaseApi.get("/api/user/availablegroups", ondone);
    }
}