import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Spinner } from 'reactstrap';
import { RioApiGet, RioApiPost, RioDatePicker, RioIf, RioResources, RioSelect, RioValidator } from "rio.react.all";
import { CustomerCategoryDistributionCacheRefreshApiModel } from '../apiModels/repair/customerCategoryDistributionCacheRefreshApiModel';
import { CustomerCategoryRepairApiModel } from '../apiModels/repair/customerCategoryRepairApiModel';
import { HttpErrorHelper } from '../helpers/RioHttpErrorHelper';
import { SelectOption } from '../helpers/RioSelectOption';
import { ErrorModal } from '../modals/RioErrorModal';


interface RepairProps extends Partial<RouteComponentProps> {
}

interface RepairSearchState {
    customerCategoryRepair: CustomerCategoryRepairApiModel;
    customerCategoryDistributionCacheRefresh: CustomerCategoryDistributionCacheRefreshApiModel
    // UI State
    loadingCustomerCategoryOk: boolean;
    loadingCustomerCategoryDistributionCacheRefreshOk: boolean;
    responseError: string;
    availableBrands: SelectOption[];
    selectedBrand: SelectOption;
}

export class Repair extends React.Component<RepairProps, RepairSearchState> {
    constructor(props: RepairProps) {
        super(props);

        this.state = {
            loadingCustomerCategoryOk: false,
            loadingCustomerCategoryDistributionCacheRefreshOk: false,
            responseError: null,
            customerCategoryRepair: new CustomerCategoryRepairApiModel(),
            customerCategoryDistributionCacheRefresh: new CustomerCategoryDistributionCacheRefreshApiModel(),
            availableBrands: [],
            selectedBrand: null
        };
    }

    private validator = new RioValidator();
    private dateFromCustomerCategoryDatePicker = React.createRef<RioDatePicker>();
    private dateFromDistributionCacheRefreshDatePicker = React.createRef<RioDatePicker>();
    private dateToDistributionCacheRefreshDatePicker = React.createRef<RioDatePicker>();

    componentDidMount() {
        const call1 = new RioApiGet<SelectOption[]>("/api/customersearch/availablebrands/true");
        call1.onDone = response => {
            if (response.success) {
                this.setState({
                    availableBrands: response.item,
                    selectedBrand: response.item.find(m => m.value === this.state.customerCategoryRepair.brandId.toString())
                });
            } else {
                this.setState({
                    availableBrands: [],
                    selectedBrand: null
                });

                //if (response.statusCode == 404) {
                //    this.setState({
                //        validationError: RioResources.find("CustomerReservePDFNotFound").text
                //    });

                //}
            }
        }
        call1.send();
    }

    customerCategoryOkClicked() {
        this.setState({
            loadingCustomerCategoryOk: true
        }, () => {

            const call = new RioApiPost<null>("/api/repair/customercategory", this.state.customerCategoryRepair);
            call.onDone = response => {
                this.setState({ loadingCustomerCategoryOk: false });

                if (!response.success) {
                    var error = HttpErrorHelper.handleHttpError(response, this, null); //handles validator errors if validator passed
                    this.setState({ responseError: error });
                }
            }
            call.send();
        });
    }

    customerCategoryDistributionCacheRefreshOkClicked() {
        this.setState({
            loadingCustomerCategoryDistributionCacheRefreshOk: true
        }, () => {
            const call = new RioApiPost<null>("/api/repair/customercategorydistributioncacherefresh", this.state.customerCategoryDistributionCacheRefresh);
            call.onDone = response => {
                this.setState({ loadingCustomerCategoryDistributionCacheRefreshOk: false });

                if (!response.success) {
                    var error = HttpErrorHelper.handleHttpError(response, this, null); //handles validator errors if validator passed
                    this.setState({ responseError: error });
                }
            }
            call.send();
        });
    }

    render() {
        return (
            <div className="repair">
                <div className="screenTitle">
                    <h1>{RioResources.find("CustomerCategoryRepair").text}</h1>
                </div>

                <div className="searchArea">
                    <div className="searchField">
                        <label>{RioResources.find("DateFrom").text}</label>
                        <RioDatePicker
                            ref={this.dateFromCustomerCategoryDatePicker}
                            name="fromDateInput"
                            validator={this.validator}
                            from={this.state.customerCategoryRepair.dateFrom}
                            onChange={(date: string) => this.handleCustomerCategoryDateFromChanged(date)}
                            nullable={true}
                        />
                    </div>

                    <div className="searchField">
                        <label>{RioResources.find("Brand").text}</label>
                        <RioSelect<SelectOption>
                            name="Brand"
                            items={this.state.availableBrands}
                            value={!!this.state.selectedBrand ? [this.state.selectedBrand] : []}
                            keySelector={(item: SelectOption) => { return item.value }}
                            descriptionSelector={(item: SelectOption) => { return item.text }}
                            onSuggestionSelected={(value: SelectOption) => { this.handleCustomerCategoryBrandChanged(value) }}
                        />
                    </div>
                </div>

                <div className="horizontalFlex formButtons">
                    <div className="buttonHolder">
                        <RioIf condition={this.state.loadingCustomerCategoryOk} then={
                            <Spinner />
                        } otherwise={
                            <button onClick={() => this.customerCategoryOkClicked()}>
                                {RioResources.find("OK").text}
                            </button>
                        } />
                    </div>
                </div>

                <div className="screenTitle">
                    <h1>{RioResources.find("CustomerCategoryDistributionCacheRefresh").text}</h1>
                </div>

                <div className="searchArea">
                    <div className="searchField">
                        <label>{RioResources.find("DateFrom").text}</label>
                        <RioDatePicker
                            ref={this.dateFromDistributionCacheRefreshDatePicker}
                            name="distributionCacheRefreshDateFrom"
                            validator={this.validator}
                            from={this.state.customerCategoryDistributionCacheRefresh.dateFrom}
                            onChange={(date: string) => this.handleDistributionCacheRefreshDateFromChanged(date)}
                            nullable={true}
                        />
                    </div>

                    <div className="searchField">
                        <label>{RioResources.find("DateTo").text}</label>
                        <RioDatePicker
                            ref={this.dateToDistributionCacheRefreshDatePicker}
                            name="distributionCacheRefreshDateTo"
                            validator={this.validator}
                            from={this.state.customerCategoryDistributionCacheRefresh.dateTo}
                            onChange={(date: string) => this.handleDistributionCacheRefreshDateToChanged(date)}
                            nullable={true}
                        />
                    </div>
                </div>

                <div className="horizontalFlex formButtons">
                    <div className="buttonHolder">
                        <RioIf condition={this.state.loadingCustomerCategoryDistributionCacheRefreshOk} then={
                            <Spinner />
                        } otherwise={
                            <button onClick={() => this.customerCategoryDistributionCacheRefreshOkClicked()}>
                                {RioResources.find("OK").text}
                            </button>
                        } />
                    </div>
                </div>

                <RioIf condition={!!this.state.responseError} then={
                    <ErrorModal text={this.state.responseError} onErrorModalClosed={() => this.setState({ responseError: null })} />
                } />
            </div>
        );
    }

    handleCustomerCategoryDateFromChanged(value: string) {
        if (value !== null && this.state.customerCategoryRepair.dateFrom !== value) {
            const customerCategoryRepair = {
                ...this.state.customerCategoryRepair,
                dateFrom: value
            };

            this.setState({
                customerCategoryRepair: customerCategoryRepair
            });
        }
    }

    handleCustomerCategoryBrandChanged(selectedOption: SelectOption) {
        if (this.state.selectedBrand?.value !== selectedOption.value) {
            const customerCategoryRepair = {
                ...this.state.customerCategoryRepair,
                brandId: parseInt(selectedOption.value)
            };

            this.setState({
                customerCategoryRepair: customerCategoryRepair
            });
        }
    }

    handleDistributionCacheRefreshDateFromChanged(value: string) {
        if (value !== null && this.state.customerCategoryDistributionCacheRefresh.dateFrom !== value) {
            const customerCategoryDistributionCacheRefresh = {
                ...this.state.customerCategoryDistributionCacheRefresh,
                dateFrom: value
            };

            this.setState({
                customerCategoryDistributionCacheRefresh: customerCategoryDistributionCacheRefresh
            });
        }
    }

    handleDistributionCacheRefreshDateToChanged(value: string) {
        if (value !== null && this.state.customerCategoryDistributionCacheRefresh.dateTo !== value) {
            const customerCategoryDistributionCacheRefresh = {
                ...this.state.customerCategoryDistributionCacheRefresh,
                dateTo: value
            };

            this.setState({
                customerCategoryDistributionCacheRefresh: customerCategoryDistributionCacheRefresh
            });
        }
    }
}