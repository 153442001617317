import { RioApiPost, RioApiGet, ApiResponse, RioApiDelete } from "rio.react.all";


export class BaseApi {

    static get<T>(apiUri: string, ondone: (response: ApiResponse<T>) => any) {
        const call = new RioApiGet<T>(apiUri);
        call.onDone = ondone;
        call.send();
    }

    static post<T>(apiUri: string, item: object, ondone: (response: ApiResponse<T>) => any) {
        const call = new RioApiPost<T>(apiUri, item);
        call.onDone = ondone;
        call.send();
    }

    static delete<T>(apiUri: string, ondone: (response: ApiResponse<T>) => any) {
        const call = new RioApiDelete<T>(apiUri);
        call.onDone = ondone;
        call.send();
    }
}