import * as React from 'react';
import { RouteComponentProps } from "react-router";
import { Spinner } from "reactstrap";
import { RioApiPost, RioIconButton, RioIf, RioInput, RioResources, RioValidator } from "rio.react.all";
import { BasePasswordResetViewModel } from "../apiModels/user/basePasswordResetViewModel";

interface IPasswordResetParams {
    key: string;
}

interface PasswordResetProps extends Partial<RouteComponentProps<IPasswordResetParams>> {
}

interface PasswordResetState {
    model: BasePasswordResetViewModel;
    loading: boolean;
    error: boolean;
    success: boolean;
}

export class PasswordReset extends React.Component<PasswordResetProps, PasswordResetState> {
    constructor(props: PasswordResetProps) {
        super(props);

        this.state = {
            model: new BasePasswordResetViewModel(),
            loading: false,
            error: false,
            success: false
        };
    }

    private validator = new RioValidator();

    componentDidMount() {
        const model: BasePasswordResetViewModel = {
            ...this.state.model,
            key: this.props.match.params.key
        };

        this.setState({
            model: model
        });
    }

    render() {
        return (
            <div className="rioLogin">
                <div className="rightPanel">
                    <RioIf condition={this.state.success} then={
                        <div className="loginform">
                            <p>{RioResources.find("PasswordResetComplete").text //Password reset success, you can now login
                            }</p>
                            <a href="/">{RioResources.find("LoginLink").text}</a>
                        </div>
                    } otherwise={
                        <div className="loginform">
                            <RioIf condition={this.state.loading} then={
                                <Spinner />
                            } otherwise={
                                <React.Fragment>
                                    <p>{RioResources.find("ChooseNewPassword").text}</p>

                                    <RioInput 
                                        name="Password" 
                                        type="password"
                                        value={this.state.model.password ?? ""}
                                        onChange={(e) => this.handlePasswordChanged(e)}
                                        validator={this.validator}
                                        validations={{ required: true }}
                                        placeholder={RioResources.find("NewPassword").text}
                                    />

                                    <RioInput 
                                        name="VerifyPassword" 
                                        type="password"
                                        value={this.state.model.verifyPassword ?? ""}
                                        onChange={(e) => this.handleConfirmPasswordChanged(e)}
                                        validator={this.validator}
                                        validations={{ required: true }}
                                        placeholder={RioResources.find("ConfirmPassword").text}
                                        onKeyDown={(e) => this.handleConfirmPasswordKeyDown(e)}
                                    />

                                    <RioIconButton 
                                        hideImage={true}
                                        resource={RioResources.find("Submit")}
                                        onClick={() => this.submit()}
                                        hasPermission={true}
                                    />
                                </React.Fragment>
                            } />
                        </div>
                    } />
                </div>
            </div>
        );
    }


    handlePasswordChanged(event: React.ChangeEvent<HTMLInputElement>) {
        const model = {
            ...this.state.model,
            password: event.target.value
        };

        this.setState({ model: model });
    }

    handleConfirmPasswordChanged(event: React.ChangeEvent<HTMLInputElement>) {
        const model = {
            ...this.state.model,
            verifyPassword: event.target.value
        };

        this.setState({ model: model });
    }

    handleConfirmPasswordKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key === 'Enter') {
            this.submit();
        }
    }

    submit() {
        this.setState({
            loading: true
        }, () => {
            var submit = new RioApiPost<BasePasswordResetViewModel>("/api/userlogin/reset", this.state.model);
            submit.onDone = (response) => { this.submitOnDone(response); };
            submit.send();
        });
    }

    submitOnDone(response: any) {
        if (response.success) {
            this.setState({
                success: true,
                loading: false
            });
        }
        else {
            this.validator.clearModelErrors(this);

            this.setState({
                error: true,
                loading: false
            }, () => {
                this.validator.addModelErrors(this, response.errors.errors ?? response.errors);
            });
        }
    }
}